import { useOnRateChangeJobUpdateSubscription } from '@/features/rate-change/gql/_gen_/rate-change.gql';
import { selectJobId } from '@/features/rate-upload/redux/rate-upload-selectors';
import { useAppSelector } from '@/redux/hooks';
import _ from 'lodash';
import { useMemo } from 'react';

export const RateChangeJobStatus = () => {
  const jobId = useAppSelector(selectJobId);

  const { data, loading, error } = useOnRateChangeJobUpdateSubscription({
    skip: !jobId,
    variables: {
      id: jobId!,
    },
  });

  const jobStatus = useMemo(() => {
    console.log('data', data);
    console.log('loading', loading);
    console.log('error', error);
    console.log('jobId', jobId);

    if (loading) return 'Loading...';
    if (error) return error.message;
    if (!data) return null;

    const job = data.onRateChangeJobUpdate;

    const message = `Job Status - ${_.startCase(job?.jobStatus)} | Rates - ${
      job?.rates?.length ?? 0
    }`;

    return message;
  }, [data, loading, error, jobId]);

  return (
    <div className='tw-flex tw-text-sky-700'>
      <p>{jobStatus}</p>
    </div>
  );
};
