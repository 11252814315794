/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRateChangeJobQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetRateChangeJobQuery = { __typename?: 'Query', getRateChangeJob?: { __typename?: 'RateChangeJob', id: string, jobType: Types.RateChangeJobType, jobStatus: Types.RateChangeJobStatus, user: string, brandCode: string, snapshotDate: string, createdAt: string, updatedAt: string } | undefined };

export type SubmitRateChangeMutationVariables = Types.Exact<{
  input: Types.SubmitRateChangeInput;
}>;


export type SubmitRateChangeMutation = { __typename?: 'Mutation', submitRateChange?: { __typename?: 'RateChangeJob', id: string, jobType: Types.RateChangeJobType, jobStatus: Types.RateChangeJobStatus, user: string, brandCode: string, snapshotDate: string, createdAt: string, updatedAt: string } | undefined };

export type OnRateChangeSubscriptionVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  snapshotDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OnRateChangeSubscription = { __typename?: 'Subscription', onRateChange?: { __typename?: 'RateChangeJob', id: string, jobType: Types.RateChangeJobType, jobStatus: Types.RateChangeJobStatus, user: string, brandCode: string, snapshotDate: string, createdAt: string, updatedAt: string } | undefined };

export type OnRateChangeJobUpdateSubscriptionVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type OnRateChangeJobUpdateSubscription = { __typename?: 'Subscription', onRateChangeJobUpdate?: { __typename?: 'RateChangeJob', id: string, jobType: Types.RateChangeJobType, jobStatus: Types.RateChangeJobStatus, user: string, brandCode: string, snapshotDate: string, createdAt: string, updatedAt: string, rates?: Array<{ __typename?: 'SubmittedRates', stayDate: string, amount: number }> | undefined } | undefined };


export const GetRateChangeJobDocument = gql`
    query GetRateChangeJob($id: ID!) {
  getRateChangeJob(id: $id) {
    id
    jobType
    jobStatus
    user
    brandCode
    snapshotDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetRateChangeJobQuery__
 *
 * To run a query within a React component, call `useGetRateChangeJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateChangeJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateChangeJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRateChangeJobQuery(baseOptions: Apollo.QueryHookOptions<GetRateChangeJobQuery, GetRateChangeJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRateChangeJobQuery, GetRateChangeJobQueryVariables>(GetRateChangeJobDocument, options);
      }
export function useGetRateChangeJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRateChangeJobQuery, GetRateChangeJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRateChangeJobQuery, GetRateChangeJobQueryVariables>(GetRateChangeJobDocument, options);
        }
export type GetRateChangeJobQueryHookResult = ReturnType<typeof useGetRateChangeJobQuery>;
export type GetRateChangeJobLazyQueryHookResult = ReturnType<typeof useGetRateChangeJobLazyQuery>;
export type GetRateChangeJobQueryResult = Apollo.QueryResult<GetRateChangeJobQuery, GetRateChangeJobQueryVariables>;
export const SubmitRateChangeDocument = gql`
    mutation SubmitRateChange($input: SubmitRateChangeInput!) {
  submitRateChange(input: $input) {
    id
    jobType
    jobStatus
    user
    brandCode
    snapshotDate
    createdAt
    updatedAt
  }
}
    `;
export type SubmitRateChangeMutationFn = Apollo.MutationFunction<SubmitRateChangeMutation, SubmitRateChangeMutationVariables>;

/**
 * __useSubmitRateChangeMutation__
 *
 * To run a mutation, you first call `useSubmitRateChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRateChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRateChangeMutation, { data, loading, error }] = useSubmitRateChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRateChangeMutation(baseOptions?: Apollo.MutationHookOptions<SubmitRateChangeMutation, SubmitRateChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitRateChangeMutation, SubmitRateChangeMutationVariables>(SubmitRateChangeDocument, options);
      }
export type SubmitRateChangeMutationHookResult = ReturnType<typeof useSubmitRateChangeMutation>;
export type SubmitRateChangeMutationResult = Apollo.MutationResult<SubmitRateChangeMutation>;
export type SubmitRateChangeMutationOptions = Apollo.BaseMutationOptions<SubmitRateChangeMutation, SubmitRateChangeMutationVariables>;
export const OnRateChangeDocument = gql`
    subscription OnRateChange($id: ID, $brandCode: String, $snapshotDate: String) {
  onRateChange(id: $id, brandCode: $brandCode, snapshotDate: $snapshotDate) {
    id
    jobType
    jobStatus
    user
    brandCode
    snapshotDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useOnRateChangeSubscription__
 *
 * To run a query within a React component, call `useOnRateChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRateChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRateChangeSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      brandCode: // value for 'brandCode'
 *      snapshotDate: // value for 'snapshotDate'
 *   },
 * });
 */
export function useOnRateChangeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnRateChangeSubscription, OnRateChangeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnRateChangeSubscription, OnRateChangeSubscriptionVariables>(OnRateChangeDocument, options);
      }
export type OnRateChangeSubscriptionHookResult = ReturnType<typeof useOnRateChangeSubscription>;
export type OnRateChangeSubscriptionResult = Apollo.SubscriptionResult<OnRateChangeSubscription>;
export const OnRateChangeJobUpdateDocument = gql`
    subscription OnRateChangeJobUpdate($id: ID) {
  onRateChangeJobUpdate(id: $id) {
    id
    jobType
    jobStatus
    user
    brandCode
    rates {
      stayDate
      amount
    }
    snapshotDate
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useOnRateChangeJobUpdateSubscription__
 *
 * To run a query within a React component, call `useOnRateChangeJobUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRateChangeJobUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRateChangeJobUpdateSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnRateChangeJobUpdateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnRateChangeJobUpdateSubscription, OnRateChangeJobUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnRateChangeJobUpdateSubscription, OnRateChangeJobUpdateSubscriptionVariables>(OnRateChangeJobUpdateDocument, options);
      }
export type OnRateChangeJobUpdateSubscriptionHookResult = ReturnType<typeof useOnRateChangeJobUpdateSubscription>;
export type OnRateChangeJobUpdateSubscriptionResult = Apollo.SubscriptionResult<OnRateChangeJobUpdateSubscription>;
export const namedOperations = {
  Query: {
    GetRateChangeJob: 'GetRateChangeJob'
  },
  Mutation: {
    SubmitRateChange: 'SubmitRateChange'
  },
  Subscription: {
    OnRateChange: 'OnRateChange',
    OnRateChangeJobUpdate: 'OnRateChangeJobUpdate'
  }
}