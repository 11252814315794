import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import {
  OktaUserState,
  isRSM,
  readOnlyMutations,
} from 'features/users/context/userContext';

import { useOktaAuth } from '@okta/okta-react';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { GraphQLError } from 'graphql';
import { labels } from 'locales/en.label';
import { useMemo } from 'react';

const url = process.env.REACT_APP_API_URL as string;
const region = process.env.REACT_APP_REGION as string;

export const ApolloProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { oktaAuth, authState } = useOktaAuth();

  const apolloClient = useMemo(() => {
    // Create an authorization link that checks if the user has the required role
    const authorizationLink = new ApolloLink((operation, forward) => {
      if (operation.query.definitions[0].kind === 'OperationDefinition') {
        const fullAccessRequired =
          operation.query.definitions[0].operation === 'mutation' &&
          !readOnlyMutations.includes(operation?.operationName);
        if (
          fullAccessRequired &&
          isRSM(authState?.idToken?.claims as OktaUserState)
        ) {
          throw new GraphQLError(labels.alerts.insufficientRole);
        }
      }

      const token = oktaAuth.getIdToken() || '';
      if (!token) {
        // If the token is missing, prevent the request from being sent
        console.error('No authentication token found!');
        return null;
      }
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      });
      return forward(operation);
    });

    const httpLink = createHttpLink({ uri: url });

    const auth = {
      type: 'OPENID_CONNECT' as const,
      jwtToken: oktaAuth.getIdToken() || '',
    };

    const link = ApolloLink.from([
      authorizationLink, // Authorization check - can a user do an action?
      createAuthLink({ url, region, auth }), // Add OIDC authentication for AWS AppSync API
      createSubscriptionHandshakeLink({ url, region, auth }, httpLink), // Handle AWS AppSync subscriptions
      httpLink,
    ]);

    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        typePolicies: {
          RecReview: {
            keyFields: ['property_id', 'stay_date'],
          },
          PropUserFavNight: {
            keyFields: ['property_id', 'user_id', 'stay_date'],
          },
          PropSeason: {
            keyFields: ['season_id'],
          },
          Query: {
            fields: {
              getPropSeasons: {
                merge: false,
              },
            },
          },
        },
      }),
      name: 'BestREV 2.0',
      version: `${process.env.REACT_APP_VERSION}`,
      connectToDevTools: process.env.NODE_ENV === 'development',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.idToken?.claims.Bestrev_groups, oktaAuth]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
