import { selectPendingRecs } from '@/features/rate-upload/redux/rate-upload-selectors';
import { useAppSelector } from '@/redux/hooks';

export const PendingRecsStatusMessage = () => {
  const pendingRecs = useAppSelector(selectPendingRecs);

  return (
    <div className='tw-flex tw-text-green'>
      {pendingRecs && pendingRecs.length > 0
        ? `${pendingRecs.length} Rows Selected`
        : null}
    </div>
  );
};
