import { GraphQLResolveInfo } from 'graphql';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type APIResponse = {
  result_rows?: Maybe<Scalars['Int']>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Affiliate = {
  __typename?: 'Affiliate';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pdr?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Array<Maybe<AffiliateProperty>>>;
  regional_affiliate?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type AffiliateProperty = {
  __typename?: 'AffiliateProperty';
  affiliate_office?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
};

export enum ArticleCategory {
  GENERAL = 'GENERAL',
  HELP = 'HELP'
}

export enum AutopilotOption {
  N = 'N',
  P = 'P',
  Y = 'Y'
}

export type CannnedSearchIds = {
  __typename?: 'CannnedSearchIds';
  search_id?: Maybe<Scalars['Int']>;
  search_name?: Maybe<Scalars['String']>;
  search_type?: Maybe<Scalars['String']>;
};

export type Competitor = {
  __typename?: 'Competitor';
  hotelId: Scalars['Int'];
  name: Scalars['String'];
  stars: Scalars['Int'];
};

export type ControlPanel = {
  __typename?: 'ControlPanel';
  allow_autopilot?: Maybe<Scalars['Boolean']>;
  support_message?: Maybe<Scalars['String']>;
};

export type CreateArticleInput = {
  body: Scalars['String'];
  category: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type CreateCompSeasonsResult = {
  __typename?: 'CreateCompSeasonsResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateEventResponse = {
  __typename?: 'CreateEventResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DailyRates = {
  __typename?: 'DailyRates';
  marketRate?: Maybe<Scalars['Float']>;
  oneDay?: Maybe<Scalars['Float']>;
  rates: Array<RateObj>;
  sevenDay?: Maybe<Scalars['Float']>;
  stayDate: Scalars['String'];
  threeDay?: Maybe<Scalars['Float']>;
};

export type DateRange = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type DeleteCompSeasonInput = {
  comp_id?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['String']>;
  season_id?: InputMaybe<Scalars['String']>;
};

export type DeleteCompSeasonsResult = {
  __typename?: 'DeleteCompSeasonsResult';
  message?: Maybe<Scalars['String']>;
  numDeletedRows?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type DeleteEventRecordResponse = {
  __typename?: 'DeleteEventRecordResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteEventResponse = {
  __typename?: 'DeleteEventResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteSearchResult = {
  __typename?: 'DeleteSearchResult';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EventBaseType = {
  __typename?: 'EventBaseType';
  category?: Maybe<EventCategoryEnum>;
  created_at?: Maybe<Scalars['String']>;
  created_by_id?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  occ_impact?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
  recurs?: Maybe<Scalars['Boolean']>;
  start_date?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by_id?: Maybe<Scalars['String']>;
};

export enum EventCategoryEnum {
  citywide = 'citywide',
  festival = 'festival',
  general = 'general',
  holiday = 'holiday',
  political = 'political',
  religious = 'religious',
  school = 'school',
  sports = 'sports'
}

export type EventFormData = {
  __typename?: 'EventFormData';
  category?: Maybe<EventCategoryEnum>;
  created_at?: Maybe<Scalars['String']>;
  created_by_id?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  occ_impact?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
  records?: Maybe<Array<Maybe<EventRecordsFormData>>>;
  recurs?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by_id?: Maybe<Scalars['String']>;
};

export type EventRecordInput = {
  end_date?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
};

export type EventRecordsFormData = {
  __typename?: 'EventRecordsFormData';
  end_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type EventRecordsType = {
  __typename?: 'EventRecordsType';
  end_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type EventResponse = {
  __typename?: 'EventResponse';
  end_date?: Maybe<Scalars['String']>;
  event?: Maybe<EventType>;
  event_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type EventType = {
  __typename?: 'EventType';
  category?: Maybe<EventCategoryEnum>;
  created_at?: Maybe<Scalars['String']>;
  created_by_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  occ_impact?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
  recurs?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by_id?: Maybe<Scalars['String']>;
};

export type FavStayNightResponse = APIResponse & {
  __typename?: 'FavStayNightResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type FilteredSeasonResponse = APIResponse & {
  __typename?: 'FilteredSeasonResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Season>>>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GenericResponse = APIResponse & {
  __typename?: 'GenericResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GroupPricing = {
  __typename?: 'GroupPricing';
  accepted?: Maybe<Scalars['Boolean']>;
  adr_fcst_override?: Maybe<Scalars['Int']>;
  avg_stay_length?: Maybe<Scalars['Float']>;
  check_in?: Maybe<Scalars['String']>;
  check_out?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['Int']>;
  comp_rooms?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  direct_var_opex?: Maybe<Scalars['Int']>;
  f_and_b_profit?: Maybe<Scalars['Int']>;
  group_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  meeting_space_benefit?: Maybe<Scalars['Int']>;
  occ_fcst_override?: Maybe<Scalars['Int']>;
  planners_adv_points?: Maybe<Scalars['Int']>;
  profit_margin?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
  rate_requested?: Maybe<Scalars['Float']>;
  rebate?: Maybe<Scalars['Int']>;
  rooms_requested?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  wash_factor?: Maybe<Scalars['Int']>;
};

export type GroupPricingInput = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  adr_fcst_override?: InputMaybe<Scalars['Int']>;
  avg_stay_length?: InputMaybe<Scalars['Float']>;
  check_in?: InputMaybe<Scalars['String']>;
  check_out?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['Int']>;
  comp_rooms?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['String']>;
  direct_var_opex?: InputMaybe<Scalars['Int']>;
  f_and_b_profit?: InputMaybe<Scalars['Int']>;
  group_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  meeting_space_benefit?: InputMaybe<Scalars['Int']>;
  occ_fcst_override?: InputMaybe<Scalars['Int']>;
  planners_adv_points?: InputMaybe<Scalars['Int']>;
  profit_margin?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['String']>;
  rate_requested?: InputMaybe<Scalars['Float']>;
  rebate?: InputMaybe<Scalars['Int']>;
  rooms_requested?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  wash_factor?: InputMaybe<Scalars['Int']>;
};

export type GroupProbability = {
  __typename?: 'GroupProbability';
  group_ratio?: Maybe<Scalars['Float']>;
  winning_probability?: Maybe<Scalars['Float']>;
};

export type IntradayForecast = {
  __typename?: 'IntradayForecast';
  a_fcst_sell?: Maybe<Scalars['Int']>;
  a_status?: Maybe<Scalars['String']>;
  actual_pkp?: Maybe<Scalars['Float']>;
  b_fcst_sell?: Maybe<Scalars['Int']>;
  b_status?: Maybe<Scalars['String']>;
  c_fcst_sell?: Maybe<Scalars['Int']>;
  c_status?: Maybe<Scalars['String']>;
  current_rate?: Maybe<Scalars['Float']>;
  current_revenue?: Maybe<Scalars['Float']>;
  d_fcst_sell?: Maybe<Scalars['Int']>;
  d_status?: Maybe<Scalars['String']>;
  delta_market_rate?: Maybe<Scalars['Float']>;
  derived_rate?: Maybe<Scalars['Float']>;
  e_fcst_sell?: Maybe<Scalars['Int']>;
  e_status?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  expected_allday_pkp?: Maybe<Scalars['Float']>;
  expected_allday_pkp_unc?: Maybe<Scalars['Float']>;
  expected_pkp?: Maybe<Scalars['Float']>;
  f_fcst_sell?: Maybe<Scalars['Int']>;
  f_status?: Maybe<Scalars['String']>;
  fcst_occ_pct?: Maybe<Scalars['Float']>;
  fcst_rev?: Maybe<Scalars['Float']>;
  g_fcst_sell?: Maybe<Scalars['Int']>;
  g_status?: Maybe<Scalars['String']>;
  h_fcst_sell?: Maybe<Scalars['Int']>;
  h_status?: Maybe<Scalars['String']>;
  incr_revenue?: Maybe<Scalars['Float']>;
  last_current_rate?: Maybe<Scalars['Float']>;
  last_hour_pkp?: Maybe<Scalars['Float']>;
  last_updated_by: Scalars['String'];
  last_updated_date: Scalars['String'];
  prev_rec_rate?: Maybe<Scalars['Float']>;
  property_id: Scalars['String'];
  rate_variance?: Maybe<Scalars['Float']>;
  reasons?: Maybe<Scalars['String']>;
  rec_autopilot: Scalars['Boolean'];
  rec_profile_category?: Maybe<Scalars['Int']>;
  rec_rate?: Maybe<Scalars['Float']>;
  snapshot_hour: Scalars['Int'];
  stay_date: Scalars['String'];
  total_fcst: Scalars['Int'];
  transient_fcst: Scalars['Int'];
  unc_transient_fcst?: Maybe<Scalars['Int']>;
};

export type KeyPair = {
  __typename?: 'KeyPair';
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LiveData = {
  __typename?: 'LiveData';
  a_otb_live?: Maybe<Scalars['Int']>;
  adr_live?: Maybe<Scalars['Float']>;
  available_rooms_live?: Maybe<Scalars['Int']>;
  b_otb_live?: Maybe<Scalars['Int']>;
  c_otb_live?: Maybe<Scalars['Int']>;
  current_otb_live?: Maybe<Scalars['Int']>;
  current_rate_live?: Maybe<Scalars['Float']>;
  current_revenue_live?: Maybe<Scalars['Float']>;
  d_otb_live?: Maybe<Scalars['Int']>;
  e_otb_live?: Maybe<Scalars['Int']>;
  f_otb_live?: Maybe<Scalars['Int']>;
  g_otb_live?: Maybe<Scalars['Int']>;
  group_blocked_live?: Maybe<Scalars['Int']>;
  group_booked_live?: Maybe<Scalars['Int']>;
  h_otb_live?: Maybe<Scalars['Int']>;
  occ_pct_live?: Maybe<Scalars['Float']>;
  ooo_live?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
  revpar_live?: Maybe<Scalars['Float']>;
  snapshot_date?: Maybe<Scalars['String']>;
  stay_date?: Maybe<Scalars['String']>;
  total_capacity_live?: Maybe<Scalars['Int']>;
  transient_booked_live?: Maybe<Scalars['Int']>;
  transient_capacity_live?: Maybe<Scalars['Int']>;
};

export type MessageResponse = {
  __typename?: 'MessageResponse';
  MessageId: Scalars['String'];
};

export type Migration = {
  __typename?: 'Migration';
  executedAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MigrationResult = {
  __typename?: 'MigrationResult';
  status?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAllPropertyRecs?: Maybe<Array<RecReview>>;
  createArticle?: Maybe<SupportArticle>;
  createCompSeasons?: Maybe<CreateCompSeasonsResult>;
  createEvent?: Maybe<CreateEventResponse>;
  createPropSeason?: Maybe<PropSeason>;
  createUser?: Maybe<User>;
  createUserConnectionLog?: Maybe<UserConnectionLog>;
  createUserHotelMetrics?: Maybe<MyMetrics>;
  deleteCompSeasons: DeleteCompSeasonsResult;
  deleteEventRecord?: Maybe<DeleteEventRecordResponse>;
  deleteEvents?: Maybe<DeleteEventResponse>;
  deleteGroupPricing?: Maybe<Scalars['String']>;
  deleteSearch?: Maybe<DeleteSearchResult>;
  deleteSeasons?: Maybe<SeasonResponse>;
  favoriteStayNight?: Maybe<PropUserFavNight>;
  logTracking?: Maybe<Tracking>;
  resetPropertyRecs?: Maybe<Array<RecReview>>;
  runMigrations?: Maybe<MigrationResult>;
  saveGroupPricing: GroupPricing;
  saveSearch?: Maybe<UserSearch>;
  setPropBestRevStatus?: Maybe<Property>;
  setSupportMessage?: Maybe<ControlPanel>;
  submitRateChange?: Maybe<RateChangeJob>;
  toggleAutopilot?: Maybe<ControlPanel>;
  toggleShowPast?: Maybe<MyMetrics>;
  updateArticle?: Maybe<SupportArticle>;
  updateCompSeasons: UpdateCompSeasonsResult;
  updateEvent?: Maybe<UpdateEventResponse>;
  updateGroupPricing: GroupPricing;
  updatePendingRecs?: Maybe<Array<RecReview>>;
  updatePropertyRoundingRule?: Maybe<Property>;
  updatePropertySeasonRates?: Maybe<UpdatePropSeasonRatesResult>;
  updateRateChangeJob?: Maybe<RateChangeJob>;
  updateRec: RecReview;
  updateSeason?: Maybe<UpdateSeasonsResult>;
  updateSeasonsPriorities?: Maybe<Array<Maybe<PropSeason>>>;
  updateUser?: Maybe<User>;
  updateUserHotelMetrics?: Maybe<MyMetrics>;
};


export type MutationacceptAllPropertyRecsArgs = {
  dateRange?: InputMaybe<DateRange>;
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['String']>;
};


export type MutationcreateArticleArgs = {
  data: CreateArticleInput;
};


export type MutationcreateCompSeasonsArgs = {
  newCompSeasons: Array<UpdateCompSeasonsInput>;
};


export type MutationcreateEventArgs = {
  event?: InputMaybe<NewEventInput>;
};


export type MutationcreatePropSeasonArgs = {
  season?: InputMaybe<PropSeasonInput>;
  updatedBy?: InputMaybe<Scalars['String']>;
};


export type MutationcreateUserArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  initials?: InputMaybe<Scalars['String']>;
  last_name: Scalars['String'];
  login_id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  okta_id?: InputMaybe<Scalars['String']>;
  prop_id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  role?: InputMaybe<UserRole>;
};


export type MutationcreateUserConnectionLogArgs = {
  data: Scalars['String'];
  email: Scalars['String'];
  success: Scalars['Boolean'];
};


export type MutationcreateUserHotelMetricsArgs = {
  metricCols: Scalars['String'];
  propertyId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationdeleteCompSeasonsArgs = {
  selectedCompSeasons: Array<DeleteCompSeasonInput>;
};


export type MutationdeleteEventRecordArgs = {
  record?: InputMaybe<EventRecordInput>;
};


export type MutationdeleteEventsArgs = {
  propertyId: Scalars['String'];
  selectedIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationdeleteGroupPricingArgs = {
  id: Scalars['String'];
};


export type MutationdeleteSearchArgs = {
  searchId?: InputMaybe<Scalars['Int']>;
};


export type MutationdeleteSeasonsArgs = {
  propertyId: Scalars['String'];
  selectedIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationfavoriteStayNightArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  stayDate?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationlogTrackingArgs = {
  tracking?: InputMaybe<TrackingInput>;
};


export type MutationresetPropertyRecsArgs = {
  dateRange?: InputMaybe<DateRange>;
  propertyId?: InputMaybe<Scalars['String']>;
};


export type MutationsaveGroupPricingArgs = {
  group: GroupPricingInput;
};


export type MutationsaveSearchArgs = {
  search?: InputMaybe<SearchInput>;
};


export type MutationsetPropBestRevStatusArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
};


export type MutationsetSupportMessageArgs = {
  message?: InputMaybe<Scalars['String']>;
};


export type MutationsubmitRateChangeArgs = {
  input: SubmitRateChangeInput;
};


export type MutationtoggleShowPastArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationupdateArticleArgs = {
  data: UpdateArticleInput;
};


export type MutationupdateCompSeasonsArgs = {
  modifiedCompSeasons: Array<UpdateCompSeasonsInput>;
};


export type MutationupdateEventArgs = {
  event?: InputMaybe<UpdateEventInput>;
  propertyId: Scalars['String'];
};


export type MutationupdateGroupPricingArgs = {
  group: GroupPricingInput;
};


export type MutationupdatePendingRecsArgs = {
  propertyId: Scalars['String'];
  status: RecReviewStatus;
};


export type MutationupdatePropertyRoundingRuleArgs = {
  propertyId: Scalars['String'];
  ruleId: Scalars['Int'];
};


export type MutationupdatePropertySeasonRatesArgs = {
  updatedPropSeasonRates?: InputMaybe<UpdatePropSeasonRatesInput>;
};


export type MutationupdateRateChangeJobArgs = {
  id: Scalars['ID'];
  jobStatus: RateChangeJobStatus;
};


export type MutationupdateRecArgs = {
  date: Scalars['String'];
  lastUpdatedBy: Scalars['String'];
  newRate: Scalars['Float'];
  propertyId: Scalars['String'];
  status: RecReviewStatus;
};


export type MutationupdateSeasonArgs = {
  updatedBy?: InputMaybe<Scalars['String']>;
  updatedSeason?: InputMaybe<UpdateSeasonInput>;
};


export type MutationupdateSeasonsPrioritiesArgs = {
  input?: InputMaybe<UpdateSeasonPrioritiesInput>;
};


export type MutationupdateUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  initials?: InputMaybe<Scalars['String']>;
  last_login?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  login_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  okta_id?: InputMaybe<Scalars['String']>;
  prop_id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  role?: InputMaybe<UserRole>;
};


export type MutationupdateUserHotelMetricsArgs = {
  metricCols: Scalars['String'];
  propertyId: Scalars['String'];
  userId: Scalars['String'];
};

export type MyMetrics = {
  __typename?: 'MyMetrics';
  columns?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
  show_past?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type NetBenefitResult = {
  __typename?: 'NetBenefitResult';
  current_otb?: Maybe<Scalars['Int']>;
  current_rate?: Maybe<Scalars['Float']>;
  fcst_rev?: Maybe<Scalars['Float']>;
  group_booked?: Maybe<Scalars['Int']>;
  py_poststayovers?: Maybe<Scalars['Int']>;
  py_priorstayovers?: Maybe<Scalars['Int']>;
  stay_date?: Maybe<Scalars['String']>;
  total_capacity?: Maybe<Scalars['Int']>;
  total_fcst?: Maybe<Scalars['Int']>;
};

export type NewEventInput = {
  category?: InputMaybe<EventCategoryEnum>;
  created_at?: InputMaybe<Scalars['String']>;
  created_by_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  occ_impact?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['String']>;
  records?: InputMaybe<Array<InputMaybe<EventRecordInput>>>;
  recurs?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['String']>;
  updated_by_id?: InputMaybe<Scalars['String']>;
};

export enum OccupancyType {
  double = 'double',
  single = 'single'
}

export type OffsetRates = {
  __typename?: 'OffsetRates';
  Per_1?: Maybe<Scalars['String']>;
  Per_2?: Maybe<Scalars['String']>;
  Per_3?: Maybe<Scalars['String']>;
  Per_4?: Maybe<Scalars['String']>;
  Per_5?: Maybe<Scalars['String']>;
  Per_6?: Maybe<Scalars['String']>;
  Per_7_Plus?: Maybe<Scalars['String']>;
};

export enum PricingStrategy {
  aggressive = 'aggressive',
  conservative = 'conservative',
  neutral = 'neutral'
}

export type PropCompSetResponse = APIResponse & {
  __typename?: 'PropCompSetResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PropCompetitor>>>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PropCompetitor = {
  __typename?: 'PropCompetitor';
  comp_id_1?: Maybe<Scalars['Int']>;
  comp_id_2?: Maybe<Scalars['Int']>;
  comp_id_3?: Maybe<Scalars['Int']>;
  comp_id_4?: Maybe<Scalars['Int']>;
  comp_id_5?: Maybe<Scalars['Int']>;
  comp_id_6?: Maybe<Scalars['Int']>;
  comp_id_7?: Maybe<Scalars['Int']>;
  comp_id_8?: Maybe<Scalars['Int']>;
  comp_id_9?: Maybe<Scalars['Int']>;
  comp_id_10?: Maybe<Scalars['Int']>;
  comp_name_1?: Maybe<Scalars['String']>;
  comp_name_2?: Maybe<Scalars['String']>;
  comp_name_3?: Maybe<Scalars['String']>;
  comp_name_4?: Maybe<Scalars['String']>;
  comp_name_5?: Maybe<Scalars['String']>;
  comp_name_6?: Maybe<Scalars['String']>;
  comp_name_7?: Maybe<Scalars['String']>;
  comp_name_8?: Maybe<Scalars['String']>;
  comp_name_9?: Maybe<Scalars['String']>;
  comp_name_10?: Maybe<Scalars['String']>;
  comp_rate_1?: Maybe<Scalars['Float']>;
  comp_rate_2?: Maybe<Scalars['Float']>;
  comp_rate_3?: Maybe<Scalars['Float']>;
  comp_rate_4?: Maybe<Scalars['Float']>;
  comp_rate_5?: Maybe<Scalars['Float']>;
  comp_rate_6?: Maybe<Scalars['Float']>;
  comp_rate_7?: Maybe<Scalars['Float']>;
  comp_rate_8?: Maybe<Scalars['Float']>;
  comp_rate_9?: Maybe<Scalars['Float']>;
  comp_rate_10?: Maybe<Scalars['Float']>;
  current_rate?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  is_favorite?: Maybe<Scalars['Boolean']>;
  last_updated_date?: Maybe<Scalars['String']>;
  market_rate?: Maybe<Scalars['Float']>;
  market_rate_lyf?: Maybe<Scalars['Float']>;
  market_rate_lyst?: Maybe<Scalars['Float']>;
  override_rate?: Maybe<Scalars['Float']>;
  property_id?: Maybe<Scalars['String']>;
  rec_rate?: Maybe<Scalars['Float']>;
  rec_status?: Maybe<Scalars['String']>;
  sellout_ind?: Maybe<Scalars['Boolean']>;
  stay_date?: Maybe<Scalars['String']>;
};

export type PropGraphView = {
  __typename?: 'PropGraphView';
  available_cap: Array<Maybe<Scalars['Int']>>;
  available_rooms: Array<Maybe<Scalars['Int']>>;
  bw_rates: Array<Maybe<Scalars['Float']>>;
  current_otb: Array<Maybe<Scalars['Int']>>;
  group_blocked: Array<Maybe<Scalars['Int']>>;
  group_booked: Array<Maybe<Scalars['Int']>>;
  market_rates: Array<Maybe<Scalars['Float']>>;
  out_of_order: Array<Maybe<Scalars['Int']>>;
  overbooking: Array<Maybe<Scalars['Int']>>;
  rec_rates: Array<Maybe<Scalars['Float']>>;
  stay_dates: Array<Maybe<Scalars['String']>>;
  total_capacity: Array<Maybe<Scalars['Int']>>;
  total_fcst: Array<Maybe<Scalars['Int']>>;
  transient_booked: Array<Maybe<Scalars['Int']>>;
  transient_cap: Array<Maybe<Scalars['Int']>>;
  yoy_rate_lyst: Array<Maybe<Scalars['Float']>>;
};

export type PropRate = {
  __typename?: 'PropRate';
  override_rate?: Maybe<Scalars['Float']>;
  property_id?: Maybe<Scalars['String']>;
  rec_status?: Maybe<Scalars['String']>;
  stay_date?: Maybe<Scalars['String']>;
};

export type PropRateLevels = {
  __typename?: 'PropRateLevels';
  a_current_otb?: Maybe<Scalars['Int']>;
  a_fcst_sell?: Maybe<Scalars['Int']>;
  a_rate_level?: Maybe<Scalars['String']>;
  a_status?: Maybe<Scalars['String']>;
  b_current_otb?: Maybe<Scalars['Int']>;
  b_fcst_sell?: Maybe<Scalars['Int']>;
  b_rate_level?: Maybe<Scalars['String']>;
  b_status?: Maybe<Scalars['String']>;
  c_current_otb?: Maybe<Scalars['Int']>;
  c_fcst_sell?: Maybe<Scalars['Int']>;
  c_rate_level?: Maybe<Scalars['String']>;
  c_status?: Maybe<Scalars['String']>;
  d_current_otb?: Maybe<Scalars['Int']>;
  d_fcst_sell?: Maybe<Scalars['Int']>;
  d_rate_level?: Maybe<Scalars['String']>;
  d_status?: Maybe<Scalars['String']>;
  e_current_otb?: Maybe<Scalars['Int']>;
  e_fcst_sell?: Maybe<Scalars['Int']>;
  e_rate_level?: Maybe<Scalars['String']>;
  e_status?: Maybe<Scalars['String']>;
  f_current_otb?: Maybe<Scalars['Int']>;
  f_fcst_sell?: Maybe<Scalars['Int']>;
  f_rate_level?: Maybe<Scalars['String']>;
  f_status?: Maybe<Scalars['String']>;
  g_current_otb?: Maybe<Scalars['Int']>;
  g_fcst_sell?: Maybe<Scalars['Int']>;
  g_rate_level?: Maybe<Scalars['String']>;
  g_status?: Maybe<Scalars['String']>;
  h_current_otb?: Maybe<Scalars['Int']>;
  h_fcst_sell?: Maybe<Scalars['Int']>;
  h_rate_level?: Maybe<Scalars['String']>;
  h_status?: Maybe<Scalars['String']>;
  last_updated_date?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
  stay_date?: Maybe<Scalars['String']>;
};

export type PropRateResponse = APIResponse & {
  __typename?: 'PropRateResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PropRate>>>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PropRecReview = {
  __typename?: 'PropRecReview';
  adr?: Maybe<Scalars['Float']>;
  adr_lyf?: Maybe<Scalars['Float']>;
  adr_lyst?: Maybe<Scalars['Float']>;
  available_cap?: Maybe<Scalars['Int']>;
  available_rooms?: Maybe<Scalars['Int']>;
  con_dmd_curr_rate?: Maybe<Scalars['Float']>;
  con_dmd_rec_rate?: Maybe<Scalars['Float']>;
  current_otb?: Maybe<Scalars['Int']>;
  current_otb_lyf?: Maybe<Scalars['Int']>;
  current_otb_lyst?: Maybe<Scalars['Int']>;
  current_rate?: Maybe<Scalars['Float']>;
  current_revenue?: Maybe<Scalars['Float']>;
  derived_rate?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  fcst_occ_pct?: Maybe<Scalars['Float']>;
  fcst_rev?: Maybe<Scalars['Float']>;
  fcst_rev_rec?: Maybe<Scalars['Float']>;
  final_rec_rate?: Maybe<Scalars['Float']>;
  group_blocked?: Maybe<Scalars['Int']>;
  group_booked?: Maybe<Scalars['Int']>;
  group_pkp_day_1?: Maybe<Scalars['Int']>;
  group_pkp_day_3?: Maybe<Scalars['Int']>;
  group_pkp_day_7?: Maybe<Scalars['Int']>;
  is_favorite?: Maybe<Scalars['Boolean']>;
  last_current_rate?: Maybe<Scalars['Float']>;
  last_updated_date?: Maybe<Scalars['String']>;
  market_rate?: Maybe<Scalars['Float']>;
  market_rate_lyf?: Maybe<Scalars['Float']>;
  market_rate_lyst?: Maybe<Scalars['Float']>;
  occ_pct?: Maybe<Scalars['Float']>;
  occ_pct_lyf?: Maybe<Scalars['Float']>;
  occ_pct_lyst?: Maybe<Scalars['Float']>;
  out_of_order?: Maybe<Scalars['Int']>;
  overbooking?: Maybe<Scalars['Int']>;
  override_rate?: Maybe<Scalars['Float']>;
  pkp_day_1?: Maybe<Scalars['Int']>;
  pkp_day_1_ly?: Maybe<Scalars['Int']>;
  pkp_day_3?: Maybe<Scalars['Int']>;
  pkp_day_3_ly?: Maybe<Scalars['Int']>;
  pkp_day_7?: Maybe<Scalars['Int']>;
  pkp_day_7_ly?: Maybe<Scalars['Int']>;
  pkp_doa?: Maybe<Scalars['Int']>;
  pkp_doa_ly?: Maybe<Scalars['Int']>;
  prev_rec_rate?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
  rate_variance?: Maybe<Scalars['Float']>;
  rec_rate?: Maybe<Scalars['Float']>;
  rec_status?: Maybe<Scalars['String']>;
  revpar?: Maybe<Scalars['Float']>;
  revpar_lyf?: Maybe<Scalars['Float']>;
  revpar_lyst?: Maybe<Scalars['Float']>;
  sellout_ind?: Maybe<Scalars['Boolean']>;
  stay_date: Scalars['String'];
  total_capacity?: Maybe<Scalars['Int']>;
  total_fcst?: Maybe<Scalars['Int']>;
  transient_booked?: Maybe<Scalars['Int']>;
  transient_cap?: Maybe<Scalars['Int']>;
  transient_fcst?: Maybe<Scalars['Int']>;
  transient_pkp_day_1?: Maybe<Scalars['Int']>;
  transient_pkp_day_3?: Maybe<Scalars['Int']>;
  transient_pkp_day_7?: Maybe<Scalars['Int']>;
  unc_dmd_curr_rate?: Maybe<Scalars['Float']>;
  unc_transient_fcst?: Maybe<Scalars['Int']>;
  yoy_rate_lyf?: Maybe<Scalars['Float']>;
  yoy_rate_lyst?: Maybe<Scalars['Float']>;
};

export type PropRecReviewResponse = APIResponse & {
  __typename?: 'PropRecReviewResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results: Array<PropRecReview>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PropSeason = {
  __typename?: 'PropSeason';
  autopilot_high?: Maybe<AutopilotOption>;
  autopilot_low?: Maybe<AutopilotOption>;
  autopilot_med?: Maybe<AutopilotOption>;
  derived_rate_offset?: Maybe<Scalars['Float']>;
  derived_rate_type?: Maybe<OccupancyType>;
  highlight_myview?: Maybe<Scalars['Boolean']>;
  last_updated_by?: Maybe<Scalars['String']>;
  last_updated_date?: Maybe<Scalars['String']>;
  max_rate?: Maybe<Scalars['Float']>;
  min_rate?: Maybe<Scalars['Float']>;
  pricing_strategy?: Maybe<PricingStrategy>;
  priority_threshold_high?: Maybe<Scalars['Float']>;
  priority_threshold_low?: Maybe<Scalars['Float']>;
  priority_threshold_med?: Maybe<Scalars['Float']>;
  property_id?: Maybe<Scalars['String']>;
  proxy_comp_id?: Maybe<Scalars['Int']>;
  proxy_offset_amt?: Maybe<Scalars['Float']>;
  proxy_type?: Maybe<ProxyType>;
  season_end?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['String']>;
  season_name?: Maybe<Scalars['String']>;
  season_priority?: Maybe<Scalars['Int']>;
  season_start?: Maybe<Scalars['String']>;
  selected_days: Array<Scalars['String']>;
  use_proxy?: Maybe<Scalars['Boolean']>;
};

export type PropSeasonInput = {
  autopilot_high?: InputMaybe<AutopilotOption>;
  autopilot_low?: InputMaybe<AutopilotOption>;
  autopilot_med?: InputMaybe<AutopilotOption>;
  derived_rate_offset?: InputMaybe<Scalars['Float']>;
  derived_rate_type?: InputMaybe<OccupancyType>;
  highlight_myview?: InputMaybe<Scalars['Boolean']>;
  last_updated_by?: InputMaybe<Scalars['String']>;
  last_updated_date?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['Float']>;
  min_rate?: InputMaybe<Scalars['Float']>;
  pricing_strategy?: InputMaybe<PricingStrategy>;
  priority_threshold_high?: InputMaybe<Scalars['Int']>;
  priority_threshold_low?: InputMaybe<Scalars['Int']>;
  priority_threshold_med?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['String']>;
  proxy_comp_id?: InputMaybe<Scalars['Int']>;
  proxy_offset_amt?: InputMaybe<Scalars['Float']>;
  proxy_type?: InputMaybe<ProxyType>;
  season_end?: InputMaybe<Scalars['String']>;
  season_id?: InputMaybe<Scalars['Int']>;
  season_name?: InputMaybe<Scalars['String']>;
  season_priority?: InputMaybe<Scalars['Int']>;
  season_start?: InputMaybe<Scalars['String']>;
  selected_days: Array<Scalars['String']>;
  use_proxy?: InputMaybe<Scalars['Boolean']>;
};

export type PropStayView = {
  __typename?: 'PropStayView';
  a_current_otb?: Maybe<Scalars['Int']>;
  a_fcst_sell?: Maybe<Scalars['Int']>;
  a_rate_level?: Maybe<Scalars['String']>;
  a_status?: Maybe<Scalars['String']>;
  available_cap?: Maybe<Scalars['Int']>;
  available_rooms?: Maybe<Scalars['Int']>;
  b_current_otb?: Maybe<Scalars['Int']>;
  b_fcst_sell?: Maybe<Scalars['Int']>;
  b_rate_level?: Maybe<Scalars['String']>;
  b_status?: Maybe<Scalars['String']>;
  c_current_otb?: Maybe<Scalars['Int']>;
  c_fcst_sell?: Maybe<Scalars['Int']>;
  c_rate_level?: Maybe<Scalars['String']>;
  c_status?: Maybe<Scalars['String']>;
  current_otb?: Maybe<Scalars['Int']>;
  current_rate?: Maybe<Scalars['Float']>;
  d_current_otb?: Maybe<Scalars['Int']>;
  d_fcst_sell?: Maybe<Scalars['Int']>;
  d_rate_level?: Maybe<Scalars['String']>;
  d_status?: Maybe<Scalars['String']>;
  derived_rate?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['String']>;
  e_current_otb?: Maybe<Scalars['Int']>;
  e_fcst_sell?: Maybe<Scalars['Int']>;
  e_rate_level?: Maybe<Scalars['String']>;
  e_status?: Maybe<Scalars['String']>;
  f_current_otb?: Maybe<Scalars['Int']>;
  f_fcst_sell?: Maybe<Scalars['Int']>;
  f_rate_level?: Maybe<Scalars['String']>;
  f_status?: Maybe<Scalars['String']>;
  final_rec_rate?: Maybe<Scalars['Float']>;
  g_current_otb?: Maybe<Scalars['Int']>;
  g_fcst_sell?: Maybe<Scalars['Int']>;
  g_rate_level?: Maybe<Scalars['String']>;
  g_status?: Maybe<Scalars['String']>;
  group_blocked?: Maybe<Scalars['Int']>;
  group_booked?: Maybe<Scalars['Int']>;
  h_current_otb?: Maybe<Scalars['Int']>;
  h_fcst_sell?: Maybe<Scalars['Int']>;
  h_rate_level?: Maybe<Scalars['String']>;
  h_status?: Maybe<Scalars['String']>;
  last_current_rate?: Maybe<Scalars['Float']>;
  last_updated_by?: Maybe<Scalars['String']>;
  last_updated_date?: Maybe<Scalars['String']>;
  market_rate?: Maybe<Scalars['Float']>;
  market_rate_lyf?: Maybe<Scalars['Float']>;
  market_rate_lyst?: Maybe<Scalars['Float']>;
  market_rates?: Maybe<Scalars['Float']>;
  out_of_order?: Maybe<Scalars['Int']>;
  overbooking?: Maybe<Scalars['Int']>;
  override_rate?: Maybe<Scalars['Float']>;
  pkp_day_1?: Maybe<Scalars['Int']>;
  pkp_day_3?: Maybe<Scalars['Int']>;
  pkp_day_7?: Maybe<Scalars['Int']>;
  prev_rec_rate?: Maybe<Scalars['Float']>;
  property_id?: Maybe<Scalars['String']>;
  rate_variance?: Maybe<Scalars['Float']>;
  rec_rate?: Maybe<Scalars['Float']>;
  rec_rates?: Maybe<Scalars['Float']>;
  rec_status?: Maybe<RecReviewStatus>;
  sellout_ind?: Maybe<Scalars['Int']>;
  stay_date?: Maybe<Scalars['String']>;
  stay_dates?: Maybe<Scalars['String']>;
  total_capacity?: Maybe<Scalars['Int']>;
  total_fcst?: Maybe<Scalars['Int']>;
  transient_booked?: Maybe<Scalars['Int']>;
  transient_cap?: Maybe<Scalars['Int']>;
  transient_fcst?: Maybe<Scalars['Int']>;
  yoy_rate_lyf?: Maybe<Scalars['Float']>;
  yoy_rate_lyst?: Maybe<Scalars['Float']>;
};

export type PropUserFavNight = {
  __typename?: 'PropUserFavNight';
  is_favorite?: Maybe<Scalars['Boolean']>;
  property_id?: Maybe<Scalars['String']>;
  stay_date?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type PropUserFavNightResponse = APIResponse & {
  __typename?: 'PropUserFavNightResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PropUserFavNight>>>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Property = {
  __typename?: 'Property';
  bestrev_active?: Maybe<Scalars['Boolean']>;
  currency_code?: Maybe<Scalars['String']>;
  property_address?: Maybe<Scalars['String']>;
  property_city?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
  property_name?: Maybe<Scalars['String']>;
  property_state?: Maybe<Scalars['String']>;
  property_zip?: Maybe<Scalars['String']>;
  rule_id?: Maybe<Scalars['Int']>;
  search_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type PropertyCompetitors = {
  __typename?: 'PropertyCompetitors';
  comp_address?: Maybe<Scalars['String']>;
  comp_city?: Maybe<Scalars['String']>;
  comp_id?: Maybe<Scalars['String']>;
  comp_name?: Maybe<Scalars['String']>;
  comp_state?: Maybe<Scalars['String']>;
  comp_weight?: Maybe<Scalars['Float']>;
  comp_zip?: Maybe<Scalars['String']>;
  last_updated_by?: Maybe<Scalars['String']>;
  last_updated_date?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  user_count?: Maybe<Scalars['Int']>;
};

export enum ProxyType {
  percent = 'percent',
  value = 'value'
}

export type Query = {
  __typename?: 'Query';
  DuplicateSeasons?: Maybe<FilteredSeasonResponse>;
  FilteredSeasons?: Maybe<FilteredSeasonResponse>;
  OverlappedSeasons?: Maybe<FilteredSeasonResponse>;
  PropCompetitorSet?: Maybe<PropCompSetResponse>;
  PropMetrics?: Maybe<PropRecReviewResponse>;
  Seasons?: Maybe<FilteredSeasonResponse>;
  getAffiliateProperties?: Maybe<Array<Maybe<AffiliateProperty>>>;
  getArticleById?: Maybe<SupportArticle>;
  getArticleBySlug?: Maybe<SupportArticle>;
  getAutopilotStatus?: Maybe<ControlPanel>;
  getCannedSearchIds?: Maybe<Array<Maybe<CannnedSearchIds>>>;
  getCategoryArticles?: Maybe<Array<Maybe<SupportArticle>>>;
  getControlPanel?: Maybe<ControlPanel>;
  getEvents?: Maybe<Array<EventResponse>>;
  getGroupProbability?: Maybe<GroupProbability>;
  getHotelRateChangeJobs?: Maybe<Array<Maybe<RateChangeJob>>>;
  getHotelRateChangeLogs?: Maybe<Array<Maybe<RateChangeLog>>>;
  getHotelRateOffsetLogs?: Maybe<Array<Maybe<RateOffsetLog>>>;
  getIntradayForecast?: Maybe<Array<Maybe<IntradayForecast>>>;
  getLiveData?: Maybe<Array<Maybe<LiveData>>>;
  getMigrations?: Maybe<Array<Maybe<Migration>>>;
  getMyMetrics?: Maybe<MyMetrics>;
  getNetBenefit: Array<Maybe<NetBenefitResult>>;
  getPropRateLevels?: Maybe<Array<Maybe<PropRateLevels>>>;
  getPropSeasons?: Maybe<Array<PropSeason>>;
  getProperties?: Maybe<Array<Maybe<Property>>>;
  getPropertyByPropertyId?: Maybe<Property>;
  getPropertyCompetitors?: Maybe<Array<Maybe<PropertyCompetitors>>>;
  getPropertyCompetitorsSet?: Maybe<Array<Maybe<PropertyCompetitors>>>;
  getPropertyRates?: Maybe<RulesRates>;
  getPropertyRecRates?: Maybe<Array<RecReview>>;
  getPropertyStayView?: Maybe<Array<Maybe<PropStayView>>>;
  getRateChangeJob?: Maybe<RateChangeJob>;
  getRateShop: RateShopResponse;
  getRateTrendBySnapshot?: Maybe<Array<Maybe<RateTrends>>>;
  getRecRateExplainers?: Maybe<Array<Maybe<RecRateExplainer>>>;
  getRoomsRemaining: Array<Maybe<RoomType>>;
  getRoundingRules?: Maybe<Array<Maybe<RoundingRules>>>;
  getSavedGroups?: Maybe<Array<Maybe<GroupPricing>>>;
  getSearchMetrics?: Maybe<Array<Maybe<SearchMetric>>>;
  getSeasonRateBoundaries?: Maybe<SeasonRateBoundary>;
  getSelloutReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  getShowPast?: Maybe<MyMetrics>;
  getSupportMessage?: Maybe<ControlPanel>;
  getUser?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
  getUserByLoginId?: Maybe<User>;
  getUserByOkta?: Maybe<User>;
  getUserSearches?: Maybe<Array<Maybe<UserSearch>>>;
  getVideoQueue: Array<Video>;
  getWinPercentages?: Maybe<WinPercentResult>;
  listUserFavNights?: Maybe<Array<Maybe<PropUserFavNight>>>;
  trackingByEmail?: Maybe<Array<Maybe<Tracking>>>;
  trackingByHotel?: Maybe<Array<Maybe<Tracking>>>;
  triggerUpload?: Maybe<MessageResponse>;
};


export type QueryDuplicateSeasonsArgs = {
  propertyId: Scalars['String'];
  seasonName: Scalars['String'];
  seasonStartDate: Scalars['String'];
};


export type QueryFilteredSeasonsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryOverlappedSeasonsArgs = {
  propertyId: Scalars['String'];
  seasonEndDate: Scalars['String'];
  seasonId: Scalars['String'];
  seasonPriority: Scalars['Int'];
  seasonStartDate: Scalars['String'];
};


export type QueryPropCompetitorSetArgs = {
  input?: InputMaybe<SearchInfo>;
  propertyId?: InputMaybe<Scalars['String']>;
};


export type QueryPropMetricsArgs = {
  input?: InputMaybe<SearchInfo>;
  propertyId?: InputMaybe<Scalars['String']>;
};


export type QuerySeasonsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  seasonType?: InputMaybe<Scalars['String']>;
};


export type QuerygetAffiliatePropertiesArgs = {
  affiliateCode: Scalars['String'];
};


export type QuerygetArticleByIdArgs = {
  id: Scalars['String'];
};


export type QuerygetArticleBySlugArgs = {
  slug: Scalars['String'];
};


export type QuerygetCategoryArticlesArgs = {
  category: Scalars['String'];
};


export type QuerygetEventsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
};


export type QuerygetGroupProbabilityArgs = {
  ratio: Scalars['Float'];
};


export type QuerygetHotelRateChangeJobsArgs = {
  brandCode: Scalars['String'];
};


export type QuerygetHotelRateChangeLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  propertyId: Scalars['String'];
};


export type QuerygetHotelRateOffsetLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  propertyId: Scalars['String'];
};


export type QuerygetIntradayForecastArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  propertyId: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};


export type QuerygetLiveDataArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
};


export type QuerygetMyMetricsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QuerygetNetBenefitArgs = {
  endDate: Scalars['String'];
  propertyId: Scalars['String'];
  startDate: Scalars['String'];
};


export type QuerygetPropRateLevelsArgs = {
  input?: InputMaybe<SearchInfo>;
  propertyId?: InputMaybe<Scalars['String']>;
};


export type QuerygetPropSeasonsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
};


export type QuerygetPropertiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
};


export type QuerygetPropertyByPropertyIdArgs = {
  propertyId: Scalars['String'];
};


export type QuerygetPropertyCompetitorsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
};


export type QuerygetPropertyCompetitorsSetArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  seasonId?: InputMaybe<Scalars['String']>;
};


export type QuerygetPropertyRatesArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  seasonId?: InputMaybe<Scalars['String']>;
};


export type QuerygetPropertyRecRatesArgs = {
  dateRange?: InputMaybe<DateRange>;
  propertyId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<RecReviewStatus>>>;
};


export type QuerygetPropertyStayViewArgs = {
  duration?: InputMaybe<Scalars['Int']>;
  propertyId?: InputMaybe<Scalars['String']>;
  selectedDays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerygetRateChangeJobArgs = {
  id: Scalars['ID'];
};


export type QuerygetRateShopArgs = {
  bar?: InputMaybe<Scalars['Boolean']>;
  changeDays?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  los?: InputMaybe<Scalars['Int']>;
  ota?: InputMaybe<Scalars['String']>;
  propertyId: Scalars['String'];
  roomType?: InputMaybe<Scalars['String']>;
};


export type QuerygetRateTrendBySnapshotArgs = {
  endSnapshot?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['String']>;
  startSnapshot?: InputMaybe<Scalars['String']>;
  stayDate?: InputMaybe<Scalars['String']>;
};


export type QuerygetRecRateExplainersArgs = {
  dateRange: DateRange;
  propertyId: Scalars['String'];
};


export type QuerygetRoomsRemainingArgs = {
  endDate: Scalars['String'];
  propertyId: Scalars['String'];
  startDate: Scalars['String'];
};


export type QuerygetSavedGroupsArgs = {
  propertyId: Scalars['String'];
  userId: Scalars['String'];
};


export type QuerygetSeasonRateBoundariesArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['String']>;
  selectedDays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QuerygetSelloutReasonsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  stayDate?: InputMaybe<Scalars['String']>;
};


export type QuerygetShowPastArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  seasonId?: InputMaybe<Scalars['String']>;
};


export type QuerygetUserArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QuerygetUserByEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QuerygetUserByLoginIdArgs = {
  loginId?: InputMaybe<Scalars['String']>;
};


export type QuerygetUserByOktaArgs = {
  okta_id?: InputMaybe<Scalars['String']>;
};


export type QuerygetUserSearchesArgs = {
  userId: Scalars['String'];
};


export type QuerygetWinPercentagesArgs = {
  ceilingRatio: Scalars['Float'];
  floorRatio: Scalars['Float'];
  optimalRatio: Scalars['Float'];
};


export type QuerylistUserFavNightsArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QuerytrackingByEmailArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  userEmail: Scalars['String'];
};


export type QuerytrackingByHotelArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  propertyId: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};


export type QuerytriggerUploadArgs = {
  propertyId: Scalars['String'];
  userName: Scalars['String'];
};

export type Rate = {
  __typename?: 'Rate';
  category: Scalars['String'];
  offsets: OffsetRates;
};

export type RateChange = {
  __typename?: 'RateChange';
  best_flex: Scalars['Boolean'];
  cancellable: Scalars['Boolean'];
  day: Scalars['Int'];
  message: Scalars['String'];
  roomName: Scalars['String'];
  roomType: Scalars['String'];
  value: Scalars['Float'];
};

export type RateChangeInput = {
  amount: Scalars['Float'];
  stayDate: Scalars['String'];
};

export type RateChangeJob = {
  __typename?: 'RateChangeJob';
  brandCode: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  jobStatus: RateChangeJobStatus;
  jobType: RateChangeJobType;
  rates?: Maybe<Array<SubmittedRates>>;
  snapshotDate: Scalars['String'];
  updatedAt: Scalars['String'];
  user: Scalars['String'];
};

export enum RateChangeJobStatus {
  completed = 'completed',
  failed = 'failed',
  pending = 'pending',
  sent = 'sent'
}

export enum RateChangeJobType {
  rate_change = 'rate_change'
}

export type RateChangeLog = {
  __typename?: 'RateChangeLog';
  createdAt: Scalars['String'];
  newRate?: Maybe<Scalars['Float']>;
  previousRate?: Maybe<Scalars['Float']>;
  stayDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type RateObj = {
  __typename?: 'RateObj';
  arrivalDate: Scalars['String'];
  best_flex: Scalars['Boolean'];
  cancellable: Scalars['Boolean'];
  changes: Array<RateChange>;
  cityTaxIncl: Scalars['Boolean'];
  currency: Scalars['String'];
  extractDateTime: Scalars['String'];
  hotelId: Scalars['Int'];
  hotelName: Scalars['String'];
  los: Scalars['Int'];
  maxPersons: Scalars['Int'];
  mealTypeIncluded: Scalars['Int'];
  message: Scalars['String'];
  otherTaxesIncl: Scalars['Boolean'];
  roomName: Scalars['String'];
  shopCurrency: Scalars['String'];
  value: Scalars['Float'];
  vatIncl: Scalars['Boolean'];
};

export type RateOffsetLog = {
  __typename?: 'RateOffsetLog';
  date: Scalars['String'];
  ratePlan?: Maybe<Scalars['String']>;
  rates: Array<Maybe<Rate>>;
  updatedAt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type RateShopResponse = {
  __typename?: 'RateShopResponse';
  compSet: Array<Competitor>;
  dailyRates: Array<DailyRates>;
};

export type RateTrends = {
  __typename?: 'RateTrends';
  comp_id_1?: Maybe<Scalars['Int']>;
  comp_id_2?: Maybe<Scalars['Int']>;
  comp_id_3?: Maybe<Scalars['Int']>;
  comp_id_4?: Maybe<Scalars['Int']>;
  comp_id_5?: Maybe<Scalars['Int']>;
  comp_id_6?: Maybe<Scalars['Int']>;
  comp_id_7?: Maybe<Scalars['Int']>;
  comp_id_8?: Maybe<Scalars['Int']>;
  comp_id_9?: Maybe<Scalars['Int']>;
  comp_id_10?: Maybe<Scalars['Int']>;
  comp_rate_1?: Maybe<Scalars['Float']>;
  comp_rate_2?: Maybe<Scalars['Float']>;
  comp_rate_3?: Maybe<Scalars['Float']>;
  comp_rate_4?: Maybe<Scalars['Float']>;
  comp_rate_5?: Maybe<Scalars['Float']>;
  comp_rate_6?: Maybe<Scalars['Float']>;
  comp_rate_7?: Maybe<Scalars['Float']>;
  comp_rate_8?: Maybe<Scalars['Float']>;
  comp_rate_9?: Maybe<Scalars['Float']>;
  comp_rate_10?: Maybe<Scalars['Float']>;
  current_otb?: Maybe<Scalars['Int']>;
  current_rate?: Maybe<Scalars['Float']>;
  last_updated_date?: Maybe<Scalars['String']>;
  market_rate?: Maybe<Scalars['Float']>;
  property_id?: Maybe<Scalars['String']>;
  rec_rate?: Maybe<Scalars['Float']>;
  stay_date?: Maybe<Scalars['String']>;
};

export enum RecAction {
  DEC = 'DEC',
  INC = 'INC',
  NC = 'NC'
}

export enum RecRateExpectation {
  HIGH = 'HIGH',
  INLINE = 'INLINE',
  LOW = 'LOW'
}

export type RecRateExplainer = {
  __typename?: 'RecRateExplainer';
  created_at?: Maybe<Scalars['String']>;
  incr_revenue?: Maybe<Scalars['Float']>;
  pickup_pace?: Maybe<RecRateExpectation>;
  pickup_ratio?: Maybe<Scalars['Float']>;
  pricing_pace?: Maybe<RecRateExpectation>;
  pricing_ratio?: Maybe<Scalars['Float']>;
  rec_action?: Maybe<RecAction>;
  stay_date?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RecReview = {
  __typename?: 'RecReview';
  created_at?: Maybe<Scalars['String']>;
  current_rate?: Maybe<Scalars['Float']>;
  derived_rate?: Maybe<Scalars['Float']>;
  final_rec_rate?: Maybe<Scalars['Float']>;
  last_current_rate?: Maybe<Scalars['Float']>;
  last_updated_by: Scalars['String'];
  last_updated_date: Scalars['String'];
  market_rate?: Maybe<Scalars['Float']>;
  market_rate_lyf?: Maybe<Scalars['Float']>;
  market_rate_lyst?: Maybe<Scalars['Float']>;
  override_rate?: Maybe<Scalars['Float']>;
  prev_rec_rate?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Int']>;
  property_id: Scalars['String'];
  rate_variance?: Maybe<Scalars['Float']>;
  rec_rate?: Maybe<Scalars['Float']>;
  rec_status?: Maybe<RecReviewStatus>;
  stay_date: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  yoy_rate_lyf?: Maybe<Scalars['Float']>;
  yoy_rate_lyst?: Maybe<Scalars['Float']>;
};

export enum RecReviewStatus {
  E = 'E',
  N = 'N',
  P = 'P',
  R = 'R',
  S = 'S'
}

export type RoomType = {
  __typename?: 'RoomType';
  property_id?: Maybe<Scalars['String']>;
  resort_id?: Maybe<Scalars['String']>;
  rt_bed_type?: Maybe<Scalars['String']>;
  rt_description?: Maybe<Scalars['String']>;
  rt_remaining?: Maybe<Scalars['Float']>;
  snapshot_date?: Maybe<Scalars['String']>;
  stay_date?: Maybe<Scalars['String']>;
};

export type RoundingRules = {
  __typename?: 'RoundingRules';
  default_col?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Float']>;
  format_above_bound?: Maybe<Scalars['Float']>;
  method?: Maybe<Scalars['String']>;
  price_bound?: Maybe<Scalars['Float']>;
  rule_id?: Maybe<Scalars['Int']>;
  use_bound?: Maybe<Scalars['Boolean']>;
};

export type RulesRates = {
  __typename?: 'RulesRates';
  derived_rate_offset?: Maybe<Scalars['Float']>;
  derived_rate_type?: Maybe<OccupancyType>;
  max_rate?: Maybe<Scalars['Float']>;
  min_rate?: Maybe<Scalars['Float']>;
  pricing_strategy?: Maybe<PricingStrategy>;
  property_id?: Maybe<Scalars['String']>;
  season_name?: Maybe<Scalars['String']>;
};

export type SearchInfo = {
  duration?: InputMaybe<Scalars['Int']>;
  searchCriteria?: InputMaybe<Array<InputMaybe<SearchMetricInfo>>>;
  searchId?: InputMaybe<Scalars['Int']>;
  searchName?: InputMaybe<Scalars['String']>;
  selectedDays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SearchInput = {
  duration?: InputMaybe<Scalars['Int']>;
  searchCriteria?: InputMaybe<Array<InputMaybe<SearchMetricInput>>>;
  searchId?: InputMaybe<Scalars['Int']>;
  searchName?: InputMaybe<Scalars['String']>;
  selectedDays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SearchLookup = {
  lookupColumn?: InputMaybe<SearchLookupType>;
  lookupValue?: InputMaybe<Scalars['String']>;
};

export enum SearchLookupType {
  SEARCH_ID = 'SEARCH_ID',
  USER_ID = 'USER_ID'
}

export type SearchMetric = {
  __typename?: 'SearchMetric';
  lhsCode?: Maybe<Scalars['String']>;
  lhsName?: Maybe<Scalars['String']>;
  operator?: Maybe<Array<Maybe<KeyPair>>>;
  rhs?: Maybe<Array<Maybe<KeyPair>>>;
};

export type SearchMetricInfo = {
  lhsCode: Scalars['String'];
  operatorCode: Scalars['String'];
  rhsCode: Scalars['String'];
  rhsCustomValue: Scalars['Float'];
};

export type SearchMetricInput = {
  lhsCode?: InputMaybe<Scalars['String']>;
  operatorCode?: InputMaybe<Scalars['String']>;
  rhsCode?: InputMaybe<Scalars['String']>;
  rhsCustomValue?: InputMaybe<Scalars['Float']>;
};

export type Season = {
  __typename?: 'Season';
  highlightInMyView?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedDate?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  seasonEndDate: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  seasonPriority?: Maybe<Scalars['Int']>;
  seasonStartDate: Scalars['String'];
  selectedDays: Array<Maybe<Scalars['String']>>;
};

export type SeasonPriorities = {
  propertyId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonPriority: Scalars['Int'];
};

export type SeasonPriority = {
  seasonId?: InputMaybe<Scalars['String']>;
  seasonName?: InputMaybe<Scalars['String']>;
  seasonPriority?: InputMaybe<Scalars['Int']>;
};

export type SeasonRateBoundary = {
  __typename?: 'SeasonRateBoundary';
  max_rate?: Maybe<Scalars['Float']>;
  min_rate?: Maybe<Scalars['Float']>;
  property_id?: Maybe<Scalars['String']>;
};

export type SeasonResponse = APIResponse & {
  __typename?: 'SeasonResponse';
  result_rows?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type StayRates = {
  propertyId?: InputMaybe<Scalars['String']>;
  rateToOverride?: InputMaybe<Scalars['Float']>;
  recStatus?: InputMaybe<Scalars['String']>;
  stayDate?: InputMaybe<Scalars['String']>;
};

export type SubmitRateChangeInput = {
  brandCode: Scalars['String'];
  user: Scalars['String'];
};

export type SubmittedRates = {
  __typename?: 'SubmittedRates';
  amount: Scalars['Float'];
  stayDate: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onRateChange?: Maybe<RateChangeJob>;
  onRateChangeJobUpdate?: Maybe<RateChangeJob>;
};


export type SubscriptiononRateChangeArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
};


export type SubscriptiononRateChangeJobUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SupportArticle = {
  __typename?: 'SupportArticle';
  body?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Tracking = {
  __typename?: 'Tracking';
  action?: Maybe<Scalars['String']>;
  autopilotEnabled?: Maybe<Scalars['Boolean']>;
  autopilotOption?: Maybe<Scalars['String']>;
  autopilotPriority?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  oktaId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  stayDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userRole?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TrackingInput = {
  action: Scalars['String'];
  autopilotEnabled?: InputMaybe<Scalars['Boolean']>;
  autopilotOption?: InputMaybe<Scalars['String']>;
  autopilotPriority?: InputMaybe<Scalars['String']>;
  oktaId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  propertyId: Scalars['String'];
  seasonId?: InputMaybe<Scalars['String']>;
  seasonName?: InputMaybe<Scalars['String']>;
  stayDate?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userId: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateArticleInput = {
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCompSeasonsInput = {
  comp_id?: InputMaybe<Scalars['String']>;
  comp_weight?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['String']>;
  season_id?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateCompSeasonsResult = {
  __typename?: 'UpdateCompSeasonsResult';
  message?: Maybe<Scalars['String']>;
  numUpdatedRows?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type UpdateEventInput = {
  category?: InputMaybe<EventCategoryEnum>;
  created_at?: InputMaybe<Scalars['String']>;
  created_by_id?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  occ_impact?: InputMaybe<Scalars['Int']>;
  property_id?: InputMaybe<Scalars['String']>;
  records?: InputMaybe<Array<InputMaybe<EventRecordInput>>>;
  recurs?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['String']>;
  updated_by_id?: InputMaybe<Scalars['String']>;
};

export type UpdateEventResponse = {
  __typename?: 'UpdateEventResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdatePropSeasonRatesInput = {
  derived_rate_offset?: InputMaybe<Scalars['Float']>;
  derived_rate_type?: InputMaybe<OccupancyType>;
  last_updated_by?: InputMaybe<Scalars['String']>;
  max_rate?: InputMaybe<Scalars['Float']>;
  min_rate?: InputMaybe<Scalars['Float']>;
  pricing_strategy?: InputMaybe<PricingStrategy>;
  property_id: Scalars['String'];
  proxy_comp_id?: InputMaybe<Scalars['Int']>;
  proxy_offset_amt?: InputMaybe<Scalars['Float']>;
  proxy_type?: InputMaybe<ProxyType>;
  season_id: Scalars['String'];
  season_name?: InputMaybe<Scalars['String']>;
  use_proxy?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePropSeasonRatesResult = {
  __typename?: 'UpdatePropSeasonRatesResult';
  message?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateSeasonInput = {
  autopilotHigh?: InputMaybe<AutopilotOption>;
  autopilotLow?: InputMaybe<AutopilotOption>;
  autopilotMed?: InputMaybe<AutopilotOption>;
  derivedRateOffset?: InputMaybe<Scalars['Float']>;
  derivedRateType?: InputMaybe<OccupancyType>;
  highlightInMyView?: InputMaybe<Scalars['Boolean']>;
  maxRate?: InputMaybe<Scalars['Float']>;
  minRate?: InputMaybe<Scalars['Float']>;
  pricingStrategy?: InputMaybe<PricingStrategy>;
  priorityThresholdHigh?: InputMaybe<Scalars['Float']>;
  priorityThresholdLow?: InputMaybe<Scalars['Float']>;
  priorityThresholdMed?: InputMaybe<Scalars['Float']>;
  propertyId: Scalars['String'];
  proxy_comp_id?: InputMaybe<Scalars['Int']>;
  proxy_offset_amt?: InputMaybe<Scalars['Float']>;
  proxy_type?: InputMaybe<ProxyType>;
  seasonEndDate: Scalars['String'];
  seasonId?: InputMaybe<Scalars['String']>;
  seasonPriority?: InputMaybe<Scalars['Int']>;
  seasonStartDate: Scalars['String'];
  selectedDays: Array<InputMaybe<Scalars['String']>>;
  use_proxy?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSeasonPrioritiesInput = {
  propertyId?: InputMaybe<Scalars['String']>;
  seasonsPriorities?: InputMaybe<Array<InputMaybe<SeasonPriority>>>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateSeasonsResult = {
  __typename?: 'UpdateSeasonsResult';
  overlappedSeasons?: Maybe<Array<Maybe<PropSeason>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  login_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  okta_id?: Maybe<Scalars['String']>;
  prop_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<UserRole>;
  super_admin?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type UserConnectionLog = {
  __typename?: 'UserConnectionLog';
  created_at?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export enum UserRole {
  admin = 'admin',
  corp_full = 'corp_full',
  corp_read = 'corp_read',
  hotel_full = 'hotel_full',
  user = 'user'
}

export type UserSearch = {
  __typename?: 'UserSearch';
  duration?: Maybe<Scalars['Int']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  searchCriteria?: Maybe<Array<Maybe<UserSearchCriteria>>>;
  searchId?: Maybe<Scalars['Int']>;
  searchName?: Maybe<Scalars['String']>;
  searchType?: Maybe<Scalars['String']>;
  selectedDays?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
};

export type UserSearchCriteria = {
  __typename?: 'UserSearchCriteria';
  lhsCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  rhsCode?: Maybe<Scalars['String']>;
  rhsCustomValue?: Maybe<Scalars['Float']>;
  searchId?: Maybe<Scalars['Int']>;
};

export type Video = {
  __typename?: 'Video';
  createdAt: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type WinPercentResult = {
  __typename?: 'WinPercentResult';
  ceiling?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  optimal?: Maybe<Scalars['Float']>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  APIResponse: ResolversTypes['FavStayNightResponse'] | ResolversTypes['FilteredSeasonResponse'] | ResolversTypes['GenericResponse'] | ResolversTypes['PropCompSetResponse'] | ResolversTypes['PropRateResponse'] | ResolversTypes['PropRecReviewResponse'] | ResolversTypes['PropUserFavNightResponse'] | ResolversTypes['SeasonResponse'];
  Affiliate: ResolverTypeWrapper<Affiliate>;
  AffiliateProperty: ResolverTypeWrapper<AffiliateProperty>;
  ArticleCategory: ArticleCategory;
  AutopilotOption: AutopilotOption;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CannnedSearchIds: ResolverTypeWrapper<CannnedSearchIds>;
  Competitor: ResolverTypeWrapper<Competitor>;
  ControlPanel: ResolverTypeWrapper<ControlPanel>;
  CreateArticleInput: CreateArticleInput;
  CreateCompSeasonsResult: ResolverTypeWrapper<CreateCompSeasonsResult>;
  CreateEventResponse: ResolverTypeWrapper<CreateEventResponse>;
  DailyRates: ResolverTypeWrapper<DailyRates>;
  DateRange: DateRange;
  DeleteCompSeasonInput: DeleteCompSeasonInput;
  DeleteCompSeasonsResult: ResolverTypeWrapper<DeleteCompSeasonsResult>;
  DeleteEventRecordResponse: ResolverTypeWrapper<DeleteEventRecordResponse>;
  DeleteEventResponse: ResolverTypeWrapper<DeleteEventResponse>;
  DeleteSearchResult: ResolverTypeWrapper<DeleteSearchResult>;
  EventBaseType: ResolverTypeWrapper<EventBaseType>;
  EventCategoryEnum: EventCategoryEnum;
  EventFormData: ResolverTypeWrapper<EventFormData>;
  EventRecordInput: EventRecordInput;
  EventRecordsFormData: ResolverTypeWrapper<EventRecordsFormData>;
  EventRecordsType: ResolverTypeWrapper<EventRecordsType>;
  EventResponse: ResolverTypeWrapper<EventResponse>;
  EventType: ResolverTypeWrapper<EventType>;
  FavStayNightResponse: ResolverTypeWrapper<FavStayNightResponse>;
  FilteredSeasonResponse: ResolverTypeWrapper<FilteredSeasonResponse>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GenericResponse: ResolverTypeWrapper<GenericResponse>;
  GroupPricing: ResolverTypeWrapper<GroupPricing>;
  GroupPricingInput: GroupPricingInput;
  GroupProbability: ResolverTypeWrapper<GroupProbability>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  IntradayForecast: ResolverTypeWrapper<IntradayForecast>;
  KeyPair: ResolverTypeWrapper<KeyPair>;
  LiveData: ResolverTypeWrapper<LiveData>;
  MessageResponse: ResolverTypeWrapper<MessageResponse>;
  Migration: ResolverTypeWrapper<Migration>;
  MigrationResult: ResolverTypeWrapper<MigrationResult>;
  Mutation: ResolverTypeWrapper<{}>;
  MyMetrics: ResolverTypeWrapper<MyMetrics>;
  NetBenefitResult: ResolverTypeWrapper<NetBenefitResult>;
  NewEventInput: NewEventInput;
  OccupancyType: OccupancyType;
  OffsetRates: ResolverTypeWrapper<OffsetRates>;
  PricingStrategy: PricingStrategy;
  PropCompSetResponse: ResolverTypeWrapper<PropCompSetResponse>;
  PropCompetitor: ResolverTypeWrapper<PropCompetitor>;
  PropGraphView: ResolverTypeWrapper<PropGraphView>;
  PropRate: ResolverTypeWrapper<PropRate>;
  PropRateLevels: ResolverTypeWrapper<PropRateLevels>;
  PropRateResponse: ResolverTypeWrapper<PropRateResponse>;
  PropRecReview: ResolverTypeWrapper<PropRecReview>;
  PropRecReviewResponse: ResolverTypeWrapper<PropRecReviewResponse>;
  PropSeason: ResolverTypeWrapper<PropSeason>;
  PropSeasonInput: PropSeasonInput;
  PropStayView: ResolverTypeWrapper<PropStayView>;
  PropUserFavNight: ResolverTypeWrapper<PropUserFavNight>;
  PropUserFavNightResponse: ResolverTypeWrapper<PropUserFavNightResponse>;
  Property: ResolverTypeWrapper<Property>;
  PropertyCompetitors: ResolverTypeWrapper<PropertyCompetitors>;
  ProxyType: ProxyType;
  Query: ResolverTypeWrapper<{}>;
  Rate: ResolverTypeWrapper<Rate>;
  RateChange: ResolverTypeWrapper<RateChange>;
  RateChangeInput: RateChangeInput;
  RateChangeJob: ResolverTypeWrapper<RateChangeJob>;
  RateChangeJobStatus: RateChangeJobStatus;
  RateChangeJobType: RateChangeJobType;
  RateChangeLog: ResolverTypeWrapper<RateChangeLog>;
  RateObj: ResolverTypeWrapper<RateObj>;
  RateOffsetLog: ResolverTypeWrapper<RateOffsetLog>;
  RateShopResponse: ResolverTypeWrapper<RateShopResponse>;
  RateTrends: ResolverTypeWrapper<RateTrends>;
  RecAction: RecAction;
  RecRateExpectation: RecRateExpectation;
  RecRateExplainer: ResolverTypeWrapper<RecRateExplainer>;
  RecReview: ResolverTypeWrapper<RecReview>;
  RecReviewStatus: RecReviewStatus;
  RoomType: ResolverTypeWrapper<RoomType>;
  RoundingRules: ResolverTypeWrapper<RoundingRules>;
  RulesRates: ResolverTypeWrapper<RulesRates>;
  SearchInfo: SearchInfo;
  SearchInput: SearchInput;
  SearchLookup: SearchLookup;
  SearchLookupType: SearchLookupType;
  SearchMetric: ResolverTypeWrapper<SearchMetric>;
  SearchMetricInfo: SearchMetricInfo;
  SearchMetricInput: SearchMetricInput;
  Season: ResolverTypeWrapper<Season>;
  SeasonPriorities: SeasonPriorities;
  SeasonPriority: SeasonPriority;
  SeasonRateBoundary: ResolverTypeWrapper<SeasonRateBoundary>;
  SeasonResponse: ResolverTypeWrapper<SeasonResponse>;
  StayRates: StayRates;
  String: ResolverTypeWrapper<Scalars['String']>;
  SubmitRateChangeInput: SubmitRateChangeInput;
  SubmittedRates: ResolverTypeWrapper<SubmittedRates>;
  Subscription: ResolverTypeWrapper<{}>;
  SupportArticle: ResolverTypeWrapper<SupportArticle>;
  Tracking: ResolverTypeWrapper<Tracking>;
  TrackingInput: TrackingInput;
  UpdateArticleInput: UpdateArticleInput;
  UpdateCompSeasonsInput: UpdateCompSeasonsInput;
  UpdateCompSeasonsResult: ResolverTypeWrapper<UpdateCompSeasonsResult>;
  UpdateEventInput: UpdateEventInput;
  UpdateEventResponse: ResolverTypeWrapper<UpdateEventResponse>;
  UpdatePropSeasonRatesInput: UpdatePropSeasonRatesInput;
  UpdatePropSeasonRatesResult: ResolverTypeWrapper<UpdatePropSeasonRatesResult>;
  UpdateSeasonInput: UpdateSeasonInput;
  UpdateSeasonPrioritiesInput: UpdateSeasonPrioritiesInput;
  UpdateSeasonsResult: ResolverTypeWrapper<UpdateSeasonsResult>;
  User: ResolverTypeWrapper<User>;
  UserConnectionLog: ResolverTypeWrapper<UserConnectionLog>;
  UserRole: UserRole;
  UserSearch: ResolverTypeWrapper<UserSearch>;
  UserSearchCriteria: ResolverTypeWrapper<UserSearchCriteria>;
  Video: ResolverTypeWrapper<Video>;
  WinPercentResult: ResolverTypeWrapper<WinPercentResult>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  APIResponse: ResolversParentTypes['FavStayNightResponse'] | ResolversParentTypes['FilteredSeasonResponse'] | ResolversParentTypes['GenericResponse'] | ResolversParentTypes['PropCompSetResponse'] | ResolversParentTypes['PropRateResponse'] | ResolversParentTypes['PropRecReviewResponse'] | ResolversParentTypes['PropUserFavNightResponse'] | ResolversParentTypes['SeasonResponse'];
  Affiliate: Affiliate;
  AffiliateProperty: AffiliateProperty;
  Boolean: Scalars['Boolean'];
  CannnedSearchIds: CannnedSearchIds;
  Competitor: Competitor;
  ControlPanel: ControlPanel;
  CreateArticleInput: CreateArticleInput;
  CreateCompSeasonsResult: CreateCompSeasonsResult;
  CreateEventResponse: CreateEventResponse;
  DailyRates: DailyRates;
  DateRange: DateRange;
  DeleteCompSeasonInput: DeleteCompSeasonInput;
  DeleteCompSeasonsResult: DeleteCompSeasonsResult;
  DeleteEventRecordResponse: DeleteEventRecordResponse;
  DeleteEventResponse: DeleteEventResponse;
  DeleteSearchResult: DeleteSearchResult;
  EventBaseType: EventBaseType;
  EventFormData: EventFormData;
  EventRecordInput: EventRecordInput;
  EventRecordsFormData: EventRecordsFormData;
  EventRecordsType: EventRecordsType;
  EventResponse: EventResponse;
  EventType: EventType;
  FavStayNightResponse: FavStayNightResponse;
  FilteredSeasonResponse: FilteredSeasonResponse;
  Float: Scalars['Float'];
  GenericResponse: GenericResponse;
  GroupPricing: GroupPricing;
  GroupPricingInput: GroupPricingInput;
  GroupProbability: GroupProbability;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  IntradayForecast: IntradayForecast;
  KeyPair: KeyPair;
  LiveData: LiveData;
  MessageResponse: MessageResponse;
  Migration: Migration;
  MigrationResult: MigrationResult;
  Mutation: {};
  MyMetrics: MyMetrics;
  NetBenefitResult: NetBenefitResult;
  NewEventInput: NewEventInput;
  OffsetRates: OffsetRates;
  PropCompSetResponse: PropCompSetResponse;
  PropCompetitor: PropCompetitor;
  PropGraphView: PropGraphView;
  PropRate: PropRate;
  PropRateLevels: PropRateLevels;
  PropRateResponse: PropRateResponse;
  PropRecReview: PropRecReview;
  PropRecReviewResponse: PropRecReviewResponse;
  PropSeason: PropSeason;
  PropSeasonInput: PropSeasonInput;
  PropStayView: PropStayView;
  PropUserFavNight: PropUserFavNight;
  PropUserFavNightResponse: PropUserFavNightResponse;
  Property: Property;
  PropertyCompetitors: PropertyCompetitors;
  Query: {};
  Rate: Rate;
  RateChange: RateChange;
  RateChangeInput: RateChangeInput;
  RateChangeJob: RateChangeJob;
  RateChangeLog: RateChangeLog;
  RateObj: RateObj;
  RateOffsetLog: RateOffsetLog;
  RateShopResponse: RateShopResponse;
  RateTrends: RateTrends;
  RecRateExplainer: RecRateExplainer;
  RecReview: RecReview;
  RoomType: RoomType;
  RoundingRules: RoundingRules;
  RulesRates: RulesRates;
  SearchInfo: SearchInfo;
  SearchInput: SearchInput;
  SearchLookup: SearchLookup;
  SearchMetric: SearchMetric;
  SearchMetricInfo: SearchMetricInfo;
  SearchMetricInput: SearchMetricInput;
  Season: Season;
  SeasonPriorities: SeasonPriorities;
  SeasonPriority: SeasonPriority;
  SeasonRateBoundary: SeasonRateBoundary;
  SeasonResponse: SeasonResponse;
  StayRates: StayRates;
  String: Scalars['String'];
  SubmitRateChangeInput: SubmitRateChangeInput;
  SubmittedRates: SubmittedRates;
  Subscription: {};
  SupportArticle: SupportArticle;
  Tracking: Tracking;
  TrackingInput: TrackingInput;
  UpdateArticleInput: UpdateArticleInput;
  UpdateCompSeasonsInput: UpdateCompSeasonsInput;
  UpdateCompSeasonsResult: UpdateCompSeasonsResult;
  UpdateEventInput: UpdateEventInput;
  UpdateEventResponse: UpdateEventResponse;
  UpdatePropSeasonRatesInput: UpdatePropSeasonRatesInput;
  UpdatePropSeasonRatesResult: UpdatePropSeasonRatesResult;
  UpdateSeasonInput: UpdateSeasonInput;
  UpdateSeasonPrioritiesInput: UpdateSeasonPrioritiesInput;
  UpdateSeasonsResult: UpdateSeasonsResult;
  User: User;
  UserConnectionLog: UserConnectionLog;
  UserSearch: UserSearch;
  UserSearchCriteria: UserSearchCriteria;
  Video: Video;
  WinPercentResult: WinPercentResult;
}>;

export type APIResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['APIResponse'] = ResolversParentTypes['APIResponse']> = ResolversObject<{
  __resolveType: TypeResolveFn<'FavStayNightResponse' | 'FilteredSeasonResponse' | 'GenericResponse' | 'PropCompSetResponse' | 'PropRateResponse' | 'PropRecReviewResponse' | 'PropUserFavNightResponse' | 'SeasonResponse', ParentType, ContextType>;
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
}>;

export type AffiliateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Affiliate'] = ResolversParentTypes['Affiliate']> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pdr?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<Maybe<ResolversTypes['AffiliateProperty']>>>, ParentType, ContextType>;
  regional_affiliate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AffiliatePropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['AffiliateProperty'] = ResolversParentTypes['AffiliateProperty']> = ResolversObject<{
  affiliate_office?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CannnedSearchIdsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CannnedSearchIds'] = ResolversParentTypes['CannnedSearchIds']> = ResolversObject<{
  search_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  search_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  search_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompetitorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Competitor'] = ResolversParentTypes['Competitor']> = ResolversObject<{
  hotelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stars?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ControlPanelResolvers<ContextType = any, ParentType extends ResolversParentTypes['ControlPanel'] = ResolversParentTypes['ControlPanel']> = ResolversObject<{
  allow_autopilot?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  support_message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateCompSeasonsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCompSeasonsResult'] = ResolversParentTypes['CreateCompSeasonsResult']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEventResponse'] = ResolversParentTypes['CreateEventResponse']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DailyRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyRates'] = ResolversParentTypes['DailyRates']> = ResolversObject<{
  marketRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  oneDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rates?: Resolver<Array<ResolversTypes['RateObj']>, ParentType, ContextType>;
  sevenDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stayDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  threeDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteCompSeasonsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCompSeasonsResult'] = ResolversParentTypes['DeleteCompSeasonsResult']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numDeletedRows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteEventRecordResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteEventRecordResponse'] = ResolversParentTypes['DeleteEventRecordResponse']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteEventResponse'] = ResolversParentTypes['DeleteEventResponse']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeleteSearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteSearchResult'] = ResolversParentTypes['DeleteSearchResult']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventBaseTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventBaseType'] = ResolversParentTypes['EventBaseType']> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['EventCategoryEnum']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_by_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occ_impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_by_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventFormDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventFormData'] = ResolversParentTypes['EventFormData']> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['EventCategoryEnum']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_by_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occ_impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EventRecordsFormData']>>>, ParentType, ContextType>;
  recurs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_by_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventRecordsFormDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventRecordsFormData'] = ResolversParentTypes['EventRecordsFormData']> = ResolversObject<{
  end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventRecordsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventRecordsType'] = ResolversParentTypes['EventRecordsType']> = ResolversObject<{
  end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventResponse'] = ResolversParentTypes['EventResponse']> = ResolversObject<{
  end_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['EventType']>, ParentType, ContextType>;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventType'] = ResolversParentTypes['EventType']> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['EventCategoryEnum']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_by_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occ_impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_by_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FavStayNightResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FavStayNightResponse'] = ResolversParentTypes['FavStayNightResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FilteredSeasonResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilteredSeasonResponse'] = ResolversParentTypes['FilteredSeasonResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Maybe<Array<Maybe<ResolversTypes['Season']>>>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GenericResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenericResponse'] = ResolversParentTypes['GenericResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GroupPricingResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupPricing'] = ResolversParentTypes['GroupPricing']> = ResolversObject<{
  accepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  adr_fcst_override?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avg_stay_length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  check_in?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  check_out?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commission?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_rooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  direct_var_opex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f_and_b_profit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meeting_space_benefit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  occ_fcst_override?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  planners_adv_points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profit_margin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate_requested?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rebate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rooms_requested?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wash_factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GroupProbabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupProbability'] = ResolversParentTypes['GroupProbability']> = ResolversObject<{
  group_ratio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  winning_probability?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IntradayForecastResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntradayForecast'] = ResolversParentTypes['IntradayForecast']> = ResolversObject<{
  a_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  a_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actual_pkp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  b_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  b_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  c_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  c_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  current_revenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  d_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  d_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delta_market_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  derived_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  e_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  e_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expected_allday_pkp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  expected_allday_pkp_unc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  expected_pkp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  f_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fcst_occ_pct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fcst_rev?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  g_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  g_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  h_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  h_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incr_revenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_hour_pkp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_updated_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prev_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  property_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate_variance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reasons?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rec_autopilot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rec_profile_category?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  snapshot_hour?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stay_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total_fcst?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transient_fcst?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unc_transient_fcst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type KeyPairResolvers<ContextType = any, ParentType extends ResolversParentTypes['KeyPair'] = ResolversParentTypes['KeyPair']> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LiveDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveData'] = ResolversParentTypes['LiveData']> = ResolversObject<{
  a_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adr_live?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  available_rooms_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  b_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  c_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_rate_live?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  current_revenue_live?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  d_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  e_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  g_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_blocked_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_booked_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  h_otb_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  occ_pct_live?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ooo_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revpar_live?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  snapshot_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_capacity_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_booked_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_capacity_live?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MessageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageResponse'] = ResolversParentTypes['MessageResponse']> = ResolversObject<{
  MessageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MigrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Migration'] = ResolversParentTypes['Migration']> = ResolversObject<{
  executedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MigrationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MigrationResult'] = ResolversParentTypes['MigrationResult']> = ResolversObject<{
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  acceptAllPropertyRecs?: Resolver<Maybe<Array<ResolversTypes['RecReview']>>, ParentType, ContextType, Partial<MutationacceptAllPropertyRecsArgs>>;
  createArticle?: Resolver<Maybe<ResolversTypes['SupportArticle']>, ParentType, ContextType, RequireFields<MutationcreateArticleArgs, 'data'>>;
  createCompSeasons?: Resolver<Maybe<ResolversTypes['CreateCompSeasonsResult']>, ParentType, ContextType, RequireFields<MutationcreateCompSeasonsArgs, 'newCompSeasons'>>;
  createEvent?: Resolver<Maybe<ResolversTypes['CreateEventResponse']>, ParentType, ContextType, Partial<MutationcreateEventArgs>>;
  createPropSeason?: Resolver<Maybe<ResolversTypes['PropSeason']>, ParentType, ContextType, Partial<MutationcreatePropSeasonArgs>>;
  createUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationcreateUserArgs, 'email' | 'first_name' | 'last_name' | 'name'>>;
  createUserConnectionLog?: Resolver<Maybe<ResolversTypes['UserConnectionLog']>, ParentType, ContextType, RequireFields<MutationcreateUserConnectionLogArgs, 'data' | 'email' | 'success'>>;
  createUserHotelMetrics?: Resolver<Maybe<ResolversTypes['MyMetrics']>, ParentType, ContextType, RequireFields<MutationcreateUserHotelMetricsArgs, 'metricCols' | 'propertyId' | 'userId'>>;
  deleteCompSeasons?: Resolver<ResolversTypes['DeleteCompSeasonsResult'], ParentType, ContextType, RequireFields<MutationdeleteCompSeasonsArgs, 'selectedCompSeasons'>>;
  deleteEventRecord?: Resolver<Maybe<ResolversTypes['DeleteEventRecordResponse']>, ParentType, ContextType, Partial<MutationdeleteEventRecordArgs>>;
  deleteEvents?: Resolver<Maybe<ResolversTypes['DeleteEventResponse']>, ParentType, ContextType, RequireFields<MutationdeleteEventsArgs, 'propertyId' | 'selectedIds'>>;
  deleteGroupPricing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationdeleteGroupPricingArgs, 'id'>>;
  deleteSearch?: Resolver<Maybe<ResolversTypes['DeleteSearchResult']>, ParentType, ContextType, Partial<MutationdeleteSearchArgs>>;
  deleteSeasons?: Resolver<Maybe<ResolversTypes['SeasonResponse']>, ParentType, ContextType, RequireFields<MutationdeleteSeasonsArgs, 'propertyId' | 'selectedIds'>>;
  favoriteStayNight?: Resolver<Maybe<ResolversTypes['PropUserFavNight']>, ParentType, ContextType, Partial<MutationfavoriteStayNightArgs>>;
  logTracking?: Resolver<Maybe<ResolversTypes['Tracking']>, ParentType, ContextType, Partial<MutationlogTrackingArgs>>;
  resetPropertyRecs?: Resolver<Maybe<Array<ResolversTypes['RecReview']>>, ParentType, ContextType, Partial<MutationresetPropertyRecsArgs>>;
  runMigrations?: Resolver<Maybe<ResolversTypes['MigrationResult']>, ParentType, ContextType>;
  saveGroupPricing?: Resolver<ResolversTypes['GroupPricing'], ParentType, ContextType, RequireFields<MutationsaveGroupPricingArgs, 'group'>>;
  saveSearch?: Resolver<Maybe<ResolversTypes['UserSearch']>, ParentType, ContextType, Partial<MutationsaveSearchArgs>>;
  setPropBestRevStatus?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType, RequireFields<MutationsetPropBestRevStatusArgs, 'status'>>;
  setSupportMessage?: Resolver<Maybe<ResolversTypes['ControlPanel']>, ParentType, ContextType, Partial<MutationsetSupportMessageArgs>>;
  submitRateChange?: Resolver<Maybe<ResolversTypes['RateChangeJob']>, ParentType, ContextType, RequireFields<MutationsubmitRateChangeArgs, 'input'>>;
  toggleAutopilot?: Resolver<Maybe<ResolversTypes['ControlPanel']>, ParentType, ContextType>;
  toggleShowPast?: Resolver<Maybe<ResolversTypes['MyMetrics']>, ParentType, ContextType, Partial<MutationtoggleShowPastArgs>>;
  updateArticle?: Resolver<Maybe<ResolversTypes['SupportArticle']>, ParentType, ContextType, RequireFields<MutationupdateArticleArgs, 'data'>>;
  updateCompSeasons?: Resolver<ResolversTypes['UpdateCompSeasonsResult'], ParentType, ContextType, RequireFields<MutationupdateCompSeasonsArgs, 'modifiedCompSeasons'>>;
  updateEvent?: Resolver<Maybe<ResolversTypes['UpdateEventResponse']>, ParentType, ContextType, RequireFields<MutationupdateEventArgs, 'propertyId'>>;
  updateGroupPricing?: Resolver<ResolversTypes['GroupPricing'], ParentType, ContextType, RequireFields<MutationupdateGroupPricingArgs, 'group'>>;
  updatePendingRecs?: Resolver<Maybe<Array<ResolversTypes['RecReview']>>, ParentType, ContextType, RequireFields<MutationupdatePendingRecsArgs, 'propertyId' | 'status'>>;
  updatePropertyRoundingRule?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType, RequireFields<MutationupdatePropertyRoundingRuleArgs, 'propertyId' | 'ruleId'>>;
  updatePropertySeasonRates?: Resolver<Maybe<ResolversTypes['UpdatePropSeasonRatesResult']>, ParentType, ContextType, Partial<MutationupdatePropertySeasonRatesArgs>>;
  updateRateChangeJob?: Resolver<Maybe<ResolversTypes['RateChangeJob']>, ParentType, ContextType, RequireFields<MutationupdateRateChangeJobArgs, 'id' | 'jobStatus'>>;
  updateRec?: Resolver<ResolversTypes['RecReview'], ParentType, ContextType, RequireFields<MutationupdateRecArgs, 'date' | 'lastUpdatedBy' | 'newRate' | 'propertyId' | 'status'>>;
  updateSeason?: Resolver<Maybe<ResolversTypes['UpdateSeasonsResult']>, ParentType, ContextType, Partial<MutationupdateSeasonArgs>>;
  updateSeasonsPriorities?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropSeason']>>>, ParentType, ContextType, Partial<MutationupdateSeasonsPrioritiesArgs>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationupdateUserArgs, 'id'>>;
  updateUserHotelMetrics?: Resolver<Maybe<ResolversTypes['MyMetrics']>, ParentType, ContextType, RequireFields<MutationupdateUserHotelMetricsArgs, 'metricCols' | 'propertyId' | 'userId'>>;
}>;

export type MyMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MyMetrics'] = ResolversParentTypes['MyMetrics']> = ResolversObject<{
  columns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  show_past?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NetBenefitResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NetBenefitResult'] = ResolversParentTypes['NetBenefitResult']> = ResolversObject<{
  current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fcst_rev?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  group_booked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  py_poststayovers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  py_priorstayovers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_capacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_fcst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OffsetRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['OffsetRates'] = ResolversParentTypes['OffsetRates']> = ResolversObject<{
  Per_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Per_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Per_3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Per_4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Per_5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Per_6?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Per_7_Plus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropCompSetResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropCompSetResponse'] = ResolversParentTypes['PropCompSetResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropCompetitor']>>>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropCompetitorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropCompetitor'] = ResolversParentTypes['PropCompetitor']> = ResolversObject<{
  comp_id_1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_3?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_4?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_5?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_6?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_7?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_8?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_9?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_10?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_name_1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_6?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_7?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_8?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_9?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name_10?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_rate_1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_6?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_7?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_8?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_9?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_10?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_favorite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  last_updated_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  override_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sellout_ind?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropGraphViewResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropGraphView'] = ResolversParentTypes['PropGraphView']> = ResolversObject<{
  available_cap?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  available_rooms?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  bw_rates?: Resolver<Array<Maybe<ResolversTypes['Float']>>, ParentType, ContextType>;
  current_otb?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  group_blocked?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  group_booked?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  market_rates?: Resolver<Array<Maybe<ResolversTypes['Float']>>, ParentType, ContextType>;
  out_of_order?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  overbooking?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  rec_rates?: Resolver<Array<Maybe<ResolversTypes['Float']>>, ParentType, ContextType>;
  stay_dates?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  total_capacity?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  total_fcst?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  transient_booked?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  transient_cap?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  yoy_rate_lyst?: Resolver<Array<Maybe<ResolversTypes['Float']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropRate'] = ResolversParentTypes['PropRate']> = ResolversObject<{
  override_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rec_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropRateLevelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropRateLevels'] = ResolversParentTypes['PropRateLevels']> = ResolversObject<{
  a_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  a_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  a_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  a_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  b_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  b_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  b_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  b_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  c_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  c_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  c_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  c_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  d_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  d_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  d_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  d_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  e_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  e_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  e_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  e_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  f_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  f_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  g_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  g_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  g_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  g_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  h_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  h_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  h_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  h_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_updated_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropRateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropRateResponse'] = ResolversParentTypes['PropRateResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropRate']>>>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropRecReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropRecReview'] = ResolversParentTypes['PropRecReview']> = ResolversObject<{
  adr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adr_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adr_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  available_cap?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  available_rooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  con_dmd_curr_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  con_dmd_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_otb_lyf?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_otb_lyst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  current_revenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  derived_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fcst_occ_pct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fcst_rev?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fcst_rev_rec?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  final_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  group_blocked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_booked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_pkp_day_1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_pkp_day_3?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_pkp_day_7?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  is_favorite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  last_current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_updated_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  occ_pct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  occ_pct_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  occ_pct_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  out_of_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  overbooking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  override_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pkp_day_1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_day_1_ly?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_day_3?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_day_3_ly?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_day_7?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_day_7_ly?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_doa?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_doa_ly?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prev_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate_variance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revpar?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revpar_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  revpar_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellout_ind?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  stay_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total_capacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_fcst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_booked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_cap?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_fcst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_pkp_day_1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_pkp_day_3?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_pkp_day_7?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  unc_dmd_curr_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unc_transient_fcst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  yoy_rate_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yoy_rate_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropRecReviewResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropRecReviewResponse'] = ResolversParentTypes['PropRecReviewResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Array<ResolversTypes['PropRecReview']>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropSeasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropSeason'] = ResolversParentTypes['PropSeason']> = ResolversObject<{
  autopilot_high?: Resolver<Maybe<ResolversTypes['AutopilotOption']>, ParentType, ContextType>;
  autopilot_low?: Resolver<Maybe<ResolversTypes['AutopilotOption']>, ParentType, ContextType>;
  autopilot_med?: Resolver<Maybe<ResolversTypes['AutopilotOption']>, ParentType, ContextType>;
  derived_rate_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  derived_rate_type?: Resolver<Maybe<ResolversTypes['OccupancyType']>, ParentType, ContextType>;
  highlight_myview?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  last_updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_updated_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricing_strategy?: Resolver<Maybe<ResolversTypes['PricingStrategy']>, ParentType, ContextType>;
  priority_threshold_high?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority_threshold_low?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority_threshold_med?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  proxy_comp_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  proxy_offset_amt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  proxy_type?: Resolver<Maybe<ResolversTypes['ProxyType']>, ParentType, ContextType>;
  season_end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  season_start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selected_days?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  use_proxy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropStayViewResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropStayView'] = ResolversParentTypes['PropStayView']> = ResolversObject<{
  a_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  a_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  a_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  a_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  available_cap?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  available_rooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  b_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  b_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  b_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  b_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  c_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  c_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  c_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  c_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  d_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  d_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  d_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  d_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  derived_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  e_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  e_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  e_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  e_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  f_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  f_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  final_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  g_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  g_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  g_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  g_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group_blocked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  group_booked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  h_current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  h_fcst_sell?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  h_rate_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  h_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_updated_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rates?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  out_of_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  overbooking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  override_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pkp_day_1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_day_3?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pkp_day_7?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prev_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate_variance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_rates?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_status?: Resolver<Maybe<ResolversTypes['RecReviewStatus']>, ParentType, ContextType>;
  sellout_ind?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stay_dates?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_capacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_fcst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_booked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_cap?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transient_fcst?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  yoy_rate_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yoy_rate_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropUserFavNightResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropUserFavNight'] = ResolversParentTypes['PropUserFavNight']> = ResolversObject<{
  is_favorite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropUserFavNightResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropUserFavNightResponse'] = ResolversParentTypes['PropUserFavNightResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropUserFavNight']>>>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Property'] = ResolversParentTypes['Property']> = ResolversObject<{
  bestrev_active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  currency_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  property_zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rule_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  search_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropertyCompetitorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyCompetitors'] = ResolversParentTypes['PropertyCompetitors']> = ResolversObject<{
  comp_address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comp_weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_updated_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  DuplicateSeasons?: Resolver<Maybe<ResolversTypes['FilteredSeasonResponse']>, ParentType, ContextType, RequireFields<QueryDuplicateSeasonsArgs, 'propertyId' | 'seasonName' | 'seasonStartDate'>>;
  FilteredSeasons?: Resolver<Maybe<ResolversTypes['FilteredSeasonResponse']>, ParentType, ContextType, Partial<QueryFilteredSeasonsArgs>>;
  OverlappedSeasons?: Resolver<Maybe<ResolversTypes['FilteredSeasonResponse']>, ParentType, ContextType, RequireFields<QueryOverlappedSeasonsArgs, 'propertyId' | 'seasonEndDate' | 'seasonId' | 'seasonPriority' | 'seasonStartDate'>>;
  PropCompetitorSet?: Resolver<Maybe<ResolversTypes['PropCompSetResponse']>, ParentType, ContextType, Partial<QueryPropCompetitorSetArgs>>;
  PropMetrics?: Resolver<Maybe<ResolversTypes['PropRecReviewResponse']>, ParentType, ContextType, Partial<QueryPropMetricsArgs>>;
  Seasons?: Resolver<Maybe<ResolversTypes['FilteredSeasonResponse']>, ParentType, ContextType, Partial<QuerySeasonsArgs>>;
  getAffiliateProperties?: Resolver<Maybe<Array<Maybe<ResolversTypes['AffiliateProperty']>>>, ParentType, ContextType, RequireFields<QuerygetAffiliatePropertiesArgs, 'affiliateCode'>>;
  getArticleById?: Resolver<Maybe<ResolversTypes['SupportArticle']>, ParentType, ContextType, RequireFields<QuerygetArticleByIdArgs, 'id'>>;
  getArticleBySlug?: Resolver<Maybe<ResolversTypes['SupportArticle']>, ParentType, ContextType, RequireFields<QuerygetArticleBySlugArgs, 'slug'>>;
  getAutopilotStatus?: Resolver<Maybe<ResolversTypes['ControlPanel']>, ParentType, ContextType>;
  getCannedSearchIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['CannnedSearchIds']>>>, ParentType, ContextType>;
  getCategoryArticles?: Resolver<Maybe<Array<Maybe<ResolversTypes['SupportArticle']>>>, ParentType, ContextType, RequireFields<QuerygetCategoryArticlesArgs, 'category'>>;
  getControlPanel?: Resolver<Maybe<ResolversTypes['ControlPanel']>, ParentType, ContextType>;
  getEvents?: Resolver<Maybe<Array<ResolversTypes['EventResponse']>>, ParentType, ContextType, Partial<QuerygetEventsArgs>>;
  getGroupProbability?: Resolver<Maybe<ResolversTypes['GroupProbability']>, ParentType, ContextType, RequireFields<QuerygetGroupProbabilityArgs, 'ratio'>>;
  getHotelRateChangeJobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['RateChangeJob']>>>, ParentType, ContextType, RequireFields<QuerygetHotelRateChangeJobsArgs, 'brandCode'>>;
  getHotelRateChangeLogs?: Resolver<Maybe<Array<Maybe<ResolversTypes['RateChangeLog']>>>, ParentType, ContextType, RequireFields<QuerygetHotelRateChangeLogsArgs, 'propertyId'>>;
  getHotelRateOffsetLogs?: Resolver<Maybe<Array<Maybe<ResolversTypes['RateOffsetLog']>>>, ParentType, ContextType, RequireFields<QuerygetHotelRateOffsetLogsArgs, 'propertyId'>>;
  getIntradayForecast?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntradayForecast']>>>, ParentType, ContextType, RequireFields<QuerygetIntradayForecastArgs, 'propertyId'>>;
  getLiveData?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveData']>>>, ParentType, ContextType, Partial<QuerygetLiveDataArgs>>;
  getMigrations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Migration']>>>, ParentType, ContextType>;
  getMyMetrics?: Resolver<Maybe<ResolversTypes['MyMetrics']>, ParentType, ContextType, Partial<QuerygetMyMetricsArgs>>;
  getNetBenefit?: Resolver<Array<Maybe<ResolversTypes['NetBenefitResult']>>, ParentType, ContextType, RequireFields<QuerygetNetBenefitArgs, 'endDate' | 'propertyId' | 'startDate'>>;
  getPropRateLevels?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropRateLevels']>>>, ParentType, ContextType, Partial<QuerygetPropRateLevelsArgs>>;
  getPropSeasons?: Resolver<Maybe<Array<ResolversTypes['PropSeason']>>, ParentType, ContextType, Partial<QuerygetPropSeasonsArgs>>;
  getProperties?: Resolver<Maybe<Array<Maybe<ResolversTypes['Property']>>>, ParentType, ContextType, RequireFields<QuerygetPropertiesArgs, 'searchTerm'>>;
  getPropertyByPropertyId?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType, RequireFields<QuerygetPropertyByPropertyIdArgs, 'propertyId'>>;
  getPropertyCompetitors?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropertyCompetitors']>>>, ParentType, ContextType, Partial<QuerygetPropertyCompetitorsArgs>>;
  getPropertyCompetitorsSet?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropertyCompetitors']>>>, ParentType, ContextType, Partial<QuerygetPropertyCompetitorsSetArgs>>;
  getPropertyRates?: Resolver<Maybe<ResolversTypes['RulesRates']>, ParentType, ContextType, Partial<QuerygetPropertyRatesArgs>>;
  getPropertyRecRates?: Resolver<Maybe<Array<ResolversTypes['RecReview']>>, ParentType, ContextType, Partial<QuerygetPropertyRecRatesArgs>>;
  getPropertyStayView?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropStayView']>>>, ParentType, ContextType, Partial<QuerygetPropertyStayViewArgs>>;
  getRateChangeJob?: Resolver<Maybe<ResolversTypes['RateChangeJob']>, ParentType, ContextType, RequireFields<QuerygetRateChangeJobArgs, 'id'>>;
  getRateShop?: Resolver<ResolversTypes['RateShopResponse'], ParentType, ContextType, RequireFields<QuerygetRateShopArgs, 'propertyId'>>;
  getRateTrendBySnapshot?: Resolver<Maybe<Array<Maybe<ResolversTypes['RateTrends']>>>, ParentType, ContextType, Partial<QuerygetRateTrendBySnapshotArgs>>;
  getRecRateExplainers?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecRateExplainer']>>>, ParentType, ContextType, RequireFields<QuerygetRecRateExplainersArgs, 'dateRange' | 'propertyId'>>;
  getRoomsRemaining?: Resolver<Array<Maybe<ResolversTypes['RoomType']>>, ParentType, ContextType, RequireFields<QuerygetRoomsRemainingArgs, 'endDate' | 'propertyId' | 'startDate'>>;
  getRoundingRules?: Resolver<Maybe<Array<Maybe<ResolversTypes['RoundingRules']>>>, ParentType, ContextType>;
  getSavedGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupPricing']>>>, ParentType, ContextType, RequireFields<QuerygetSavedGroupsArgs, 'propertyId' | 'userId'>>;
  getSearchMetrics?: Resolver<Maybe<Array<Maybe<ResolversTypes['SearchMetric']>>>, ParentType, ContextType>;
  getSeasonRateBoundaries?: Resolver<Maybe<ResolversTypes['SeasonRateBoundary']>, ParentType, ContextType, Partial<QuerygetSeasonRateBoundariesArgs>>;
  getSelloutReasons?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, Partial<QuerygetSelloutReasonsArgs>>;
  getShowPast?: Resolver<Maybe<ResolversTypes['MyMetrics']>, ParentType, ContextType, Partial<QuerygetShowPastArgs>>;
  getSupportMessage?: Resolver<Maybe<ResolversTypes['ControlPanel']>, ParentType, ContextType>;
  getUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<QuerygetUserArgs>>;
  getUserByEmail?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<QuerygetUserByEmailArgs>>;
  getUserByLoginId?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<QuerygetUserByLoginIdArgs>>;
  getUserByOkta?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<QuerygetUserByOktaArgs>>;
  getUserSearches?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserSearch']>>>, ParentType, ContextType, RequireFields<QuerygetUserSearchesArgs, 'userId'>>;
  getVideoQueue?: Resolver<Array<ResolversTypes['Video']>, ParentType, ContextType>;
  getWinPercentages?: Resolver<Maybe<ResolversTypes['WinPercentResult']>, ParentType, ContextType, RequireFields<QuerygetWinPercentagesArgs, 'ceilingRatio' | 'floorRatio' | 'optimalRatio'>>;
  listUserFavNights?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropUserFavNight']>>>, ParentType, ContextType, Partial<QuerylistUserFavNightsArgs>>;
  trackingByEmail?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tracking']>>>, ParentType, ContextType, RequireFields<QuerytrackingByEmailArgs, 'userEmail'>>;
  trackingByHotel?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tracking']>>>, ParentType, ContextType, RequireFields<QuerytrackingByHotelArgs, 'propertyId'>>;
  triggerUpload?: Resolver<Maybe<ResolversTypes['MessageResponse']>, ParentType, ContextType, RequireFields<QuerytriggerUploadArgs, 'propertyId' | 'userName'>>;
}>;

export type RateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rate'] = ResolversParentTypes['Rate']> = ResolversObject<{
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offsets?: Resolver<ResolversTypes['OffsetRates'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateChange'] = ResolversParentTypes['RateChange']> = ResolversObject<{
  best_flex?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cancellable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roomName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roomType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateChangeJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateChangeJob'] = ResolversParentTypes['RateChangeJob']> = ResolversObject<{
  brandCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jobStatus?: Resolver<ResolversTypes['RateChangeJobStatus'], ParentType, ContextType>;
  jobType?: Resolver<ResolversTypes['RateChangeJobType'], ParentType, ContextType>;
  rates?: Resolver<Maybe<Array<ResolversTypes['SubmittedRates']>>, ParentType, ContextType>;
  snapshotDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateChangeLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateChangeLog'] = ResolversParentTypes['RateChangeLog']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stayDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateObjResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateObj'] = ResolversParentTypes['RateObj']> = ResolversObject<{
  arrivalDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  best_flex?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cancellable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  changes?: Resolver<Array<ResolversTypes['RateChange']>, ParentType, ContextType>;
  cityTaxIncl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extractDateTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hotelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hotelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  los?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxPersons?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mealTypeIncluded?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  otherTaxesIncl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  roomName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  vatIncl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateOffsetLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateOffsetLog'] = ResolversParentTypes['RateOffsetLog']> = ResolversObject<{
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ratePlan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rates?: Resolver<Array<Maybe<ResolversTypes['Rate']>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateShopResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateShopResponse'] = ResolversParentTypes['RateShopResponse']> = ResolversObject<{
  compSet?: Resolver<Array<ResolversTypes['Competitor']>, ParentType, ContextType>;
  dailyRates?: Resolver<Array<ResolversTypes['DailyRates']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RateTrendsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateTrends'] = ResolversParentTypes['RateTrends']> = ResolversObject<{
  comp_id_1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_3?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_4?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_5?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_6?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_7?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_8?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_9?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_id_10?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  comp_rate_1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_6?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_7?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_8?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_9?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comp_rate_10?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  current_otb?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_updated_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecRateExplainerResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecRateExplainer'] = ResolversParentTypes['RecRateExplainer']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incr_revenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pickup_pace?: Resolver<Maybe<ResolversTypes['RecRateExpectation']>, ParentType, ContextType>;
  pickup_ratio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricing_pace?: Resolver<Maybe<ResolversTypes['RecRateExpectation']>, ParentType, ContextType>;
  pricing_ratio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_action?: Resolver<Maybe<ResolversTypes['RecAction']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecReview'] = ResolversParentTypes['RecReview']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  derived_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  final_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_current_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  last_updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_updated_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  market_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  market_rate_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  override_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prev_rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  property_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate_variance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rec_status?: Resolver<Maybe<ResolversTypes['RecReviewStatus']>, ParentType, ContextType>;
  stay_date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yoy_rate_lyf?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yoy_rate_lyst?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoomTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoomType'] = ResolversParentTypes['RoomType']> = ResolversObject<{
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resort_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rt_bed_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rt_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rt_remaining?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  snapshot_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stay_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoundingRulesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingRules'] = ResolversParentTypes['RoundingRules']> = ResolversObject<{
  default_col?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  format_above_bound?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price_bound?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rule_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  use_bound?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RulesRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RulesRates'] = ResolversParentTypes['RulesRates']> = ResolversObject<{
  derived_rate_offset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  derived_rate_type?: Resolver<Maybe<ResolversTypes['OccupancyType']>, ParentType, ContextType>;
  max_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricing_strategy?: Resolver<Maybe<ResolversTypes['PricingStrategy']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SearchMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchMetric'] = ResolversParentTypes['SearchMetric']> = ResolversObject<{
  lhsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lhsName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operator?: Resolver<Maybe<Array<Maybe<ResolversTypes['KeyPair']>>>, ParentType, ContextType>;
  rhs?: Resolver<Maybe<Array<Maybe<ResolversTypes['KeyPair']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SeasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Season'] = ResolversParentTypes['Season']> = ResolversObject<{
  highlightInMyView?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastUpdatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdatedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seasonEndDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seasonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seasonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seasonPriority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonStartDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selectedDays?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SeasonRateBoundaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeasonRateBoundary'] = ResolversParentTypes['SeasonRateBoundary']> = ResolversObject<{
  max_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  property_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SeasonResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeasonResponse'] = ResolversParentTypes['SeasonResponse']> = ResolversObject<{
  result_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  results?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubmittedRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmittedRates'] = ResolversParentTypes['SubmittedRates']> = ResolversObject<{
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stayDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  onRateChange?: SubscriptionResolver<Maybe<ResolversTypes['RateChangeJob']>, "onRateChange", ParentType, ContextType, Partial<SubscriptiononRateChangeArgs>>;
  onRateChangeJobUpdate?: SubscriptionResolver<Maybe<ResolversTypes['RateChangeJob']>, "onRateChangeJobUpdate", ParentType, ContextType, Partial<SubscriptiononRateChangeJobUpdateArgs>>;
}>;

export type SupportArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportArticle'] = ResolversParentTypes['SupportArticle']> = ResolversObject<{
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrackingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tracking'] = ResolversParentTypes['Tracking']> = ResolversObject<{
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autopilotEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  autopilotOption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autopilotPriority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oktaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seasonName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stayDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateCompSeasonsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCompSeasonsResult'] = ResolversParentTypes['UpdateCompSeasonsResult']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numUpdatedRows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateEventResponse'] = ResolversParentTypes['UpdateEventResponse']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdatePropSeasonRatesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePropSeasonRatesResult'] = ResolversParentTypes['UpdatePropSeasonRatesResult']> = ResolversObject<{
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  season_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateSeasonsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateSeasonsResult'] = ResolversParentTypes['UpdateSeasonsResult']> = ResolversObject<{
  overlappedSeasons?: Resolver<Maybe<Array<Maybe<ResolversTypes['PropSeason']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initials?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_login?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  login_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  okta_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prop_id?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['UserRole']>, ParentType, ContextType>;
  super_admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserConnectionLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConnectionLog'] = ResolversParentTypes['UserConnectionLog']> = ResolversObject<{
  created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSearchResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSearch'] = ResolversParentTypes['UserSearch']> = ResolversObject<{
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isEditable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  searchCriteria?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserSearchCriteria']>>>, ParentType, ContextType>;
  searchId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  searchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selectedDays?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserSearchCriteriaResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSearchCriteria'] = ResolversParentTypes['UserSearchCriteria']> = ResolversObject<{
  lhsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operatorCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rhsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rhsCustomValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  searchId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WinPercentResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['WinPercentResult'] = ResolversParentTypes['WinPercentResult']> = ResolversObject<{
  ceiling?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optimal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  APIResponse?: APIResponseResolvers<ContextType>;
  Affiliate?: AffiliateResolvers<ContextType>;
  AffiliateProperty?: AffiliatePropertyResolvers<ContextType>;
  CannnedSearchIds?: CannnedSearchIdsResolvers<ContextType>;
  Competitor?: CompetitorResolvers<ContextType>;
  ControlPanel?: ControlPanelResolvers<ContextType>;
  CreateCompSeasonsResult?: CreateCompSeasonsResultResolvers<ContextType>;
  CreateEventResponse?: CreateEventResponseResolvers<ContextType>;
  DailyRates?: DailyRatesResolvers<ContextType>;
  DeleteCompSeasonsResult?: DeleteCompSeasonsResultResolvers<ContextType>;
  DeleteEventRecordResponse?: DeleteEventRecordResponseResolvers<ContextType>;
  DeleteEventResponse?: DeleteEventResponseResolvers<ContextType>;
  DeleteSearchResult?: DeleteSearchResultResolvers<ContextType>;
  EventBaseType?: EventBaseTypeResolvers<ContextType>;
  EventFormData?: EventFormDataResolvers<ContextType>;
  EventRecordsFormData?: EventRecordsFormDataResolvers<ContextType>;
  EventRecordsType?: EventRecordsTypeResolvers<ContextType>;
  EventResponse?: EventResponseResolvers<ContextType>;
  EventType?: EventTypeResolvers<ContextType>;
  FavStayNightResponse?: FavStayNightResponseResolvers<ContextType>;
  FilteredSeasonResponse?: FilteredSeasonResponseResolvers<ContextType>;
  GenericResponse?: GenericResponseResolvers<ContextType>;
  GroupPricing?: GroupPricingResolvers<ContextType>;
  GroupProbability?: GroupProbabilityResolvers<ContextType>;
  IntradayForecast?: IntradayForecastResolvers<ContextType>;
  KeyPair?: KeyPairResolvers<ContextType>;
  LiveData?: LiveDataResolvers<ContextType>;
  MessageResponse?: MessageResponseResolvers<ContextType>;
  Migration?: MigrationResolvers<ContextType>;
  MigrationResult?: MigrationResultResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MyMetrics?: MyMetricsResolvers<ContextType>;
  NetBenefitResult?: NetBenefitResultResolvers<ContextType>;
  OffsetRates?: OffsetRatesResolvers<ContextType>;
  PropCompSetResponse?: PropCompSetResponseResolvers<ContextType>;
  PropCompetitor?: PropCompetitorResolvers<ContextType>;
  PropGraphView?: PropGraphViewResolvers<ContextType>;
  PropRate?: PropRateResolvers<ContextType>;
  PropRateLevels?: PropRateLevelsResolvers<ContextType>;
  PropRateResponse?: PropRateResponseResolvers<ContextType>;
  PropRecReview?: PropRecReviewResolvers<ContextType>;
  PropRecReviewResponse?: PropRecReviewResponseResolvers<ContextType>;
  PropSeason?: PropSeasonResolvers<ContextType>;
  PropStayView?: PropStayViewResolvers<ContextType>;
  PropUserFavNight?: PropUserFavNightResolvers<ContextType>;
  PropUserFavNightResponse?: PropUserFavNightResponseResolvers<ContextType>;
  Property?: PropertyResolvers<ContextType>;
  PropertyCompetitors?: PropertyCompetitorsResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Rate?: RateResolvers<ContextType>;
  RateChange?: RateChangeResolvers<ContextType>;
  RateChangeJob?: RateChangeJobResolvers<ContextType>;
  RateChangeLog?: RateChangeLogResolvers<ContextType>;
  RateObj?: RateObjResolvers<ContextType>;
  RateOffsetLog?: RateOffsetLogResolvers<ContextType>;
  RateShopResponse?: RateShopResponseResolvers<ContextType>;
  RateTrends?: RateTrendsResolvers<ContextType>;
  RecRateExplainer?: RecRateExplainerResolvers<ContextType>;
  RecReview?: RecReviewResolvers<ContextType>;
  RoomType?: RoomTypeResolvers<ContextType>;
  RoundingRules?: RoundingRulesResolvers<ContextType>;
  RulesRates?: RulesRatesResolvers<ContextType>;
  SearchMetric?: SearchMetricResolvers<ContextType>;
  Season?: SeasonResolvers<ContextType>;
  SeasonRateBoundary?: SeasonRateBoundaryResolvers<ContextType>;
  SeasonResponse?: SeasonResponseResolvers<ContextType>;
  SubmittedRates?: SubmittedRatesResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SupportArticle?: SupportArticleResolvers<ContextType>;
  Tracking?: TrackingResolvers<ContextType>;
  UpdateCompSeasonsResult?: UpdateCompSeasonsResultResolvers<ContextType>;
  UpdateEventResponse?: UpdateEventResponseResolvers<ContextType>;
  UpdatePropSeasonRatesResult?: UpdatePropSeasonRatesResultResolvers<ContextType>;
  UpdateSeasonsResult?: UpdateSeasonsResultResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserConnectionLog?: UserConnectionLogResolvers<ContextType>;
  UserSearch?: UserSearchResolvers<ContextType>;
  UserSearchCriteria?: UserSearchCriteriaResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
  WinPercentResult?: WinPercentResultResolvers<ContextType>;
}>;

