import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  NewValueParams,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import {
  addDaysStr,
  dataDate,
  dayRange,
  today,
  todayAdd,
  todaySubtract,
} from 'features/dates/date-helpers';
import { setMyMetrics, setTabs } from 'features/my-view/redux/my-view-slice';
import {
  selectAllRowsSelected,
  selectPendingRecs,
  selectPolling,
  selectUploading,
} from 'features/rate-upload/redux/rate-upload-selectors';
import {
  useGetIntradayForecastQuery,
  useGetRecRateQueryExplainersQuery,
} from 'features/rec-rates/gql/_gen_/property-rec-rates.gql';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MdCheckCircleOutline,
  MdMoreHoriz,
  MdRefresh,
  MdSimCardDownload,
} from 'react-icons/md';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  calcTotalForecast,
  daysOfWeekSort,
  formattedCurrency,
  renderLiveData,
  renderPriority,
  renderRecStatusCellStyle,
  twoDecimalRateFormatter,
} from './tableCustomCellRender';
import { ColumnObj, useDashboardColumns } from './useDashboardColumns';
import {
  FavClickParams,
  useDashboardController,
} from './useDashboardController';

import { PendingRecsStatusMessage } from '@/features/rate-change/components/pending-recs-status-message';
import { RateChangeJobStatus } from '@/features/rate-change/components/rate-change-job-status';
import { HelpOutline } from '@mui/icons-material';
import { Switch } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AgGridReact } from 'ag-grid-react';
import styles from 'common/_variables.module.scss';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { ExportCSV } from 'components/ExportCSV/ExportCSV';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'components/Modal/useModal';
import { useExportContext } from 'context/exportContext';
import { usePropertyContext } from 'context/propertyContext';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import useWindowDimensions from 'features/default/hook/window-size';
import { selectShowAskRev } from 'features/header/redux/selectors';
import { selectRecReviewTab } from 'features/my-view/redux/selectors';
import { selectProperty } from 'features/property/redux/selectors';
import { RateUploadButton } from 'features/rate-upload/components/rate-upload-button';
import { useRateUpload } from 'features/rate-upload/hooks/use-rate-upload';
import { setAllRowsSelected } from 'features/rate-upload/redux/rate-upload-slice';
import { useUser } from 'features/users/context/userContext';
import { getCsvFormatHeader } from 'helpers/csvHelpers';
import { labels } from 'locales/en.label';
import { pickBy } from 'lodash';
import { BsFillFileBarGraphFill } from 'react-icons/bs';
import { IoStar } from 'react-icons/io5';
import styled from 'styled-components';
import ForecastInfoTooltip from './components/forecast-info-tooltip/forecast-info-tooltip';
import PriceDecisionTooltip from './components/price-decision-tooltip/price-decision-tooltip';
import { RateShopModal } from './components/rate-shop-modal/rate-shop-modal';
import { Tabs } from './components/tabs';
import { MenuOptionType } from './config';
import { DashboardTable } from './DashboardTable';
import { FavStayDate } from './FavStayDate';
import { useMyMetrics } from './hooks/useMyMetrics';
import { OverrideCell } from './override-cell';
import { RateCellRender } from './RateCellRender';
import { RecAlert } from './RecAlert';
import { StayDateWithSeasonTooltip } from './StayDateWithSeasonTooltip';

interface DashboardContentProps {
  askRevId?: string;
  startDate: string | undefined;
  paginationMaxDuration: number;
}

export type Competitors =
  | {
      comp_name_1: string;
      comp_name_2: string;
      comp_name_3: string;
      comp_name_4: string;
      comp_name_5: string;
      comp_name_6: string;
      comp_name_7: string;
      comp_name_8: string;
      comp_name_9: string;
      comp_name_10: string;
      comp_id_1: string;
      comp_id_2: string;
      comp_id_3: string;
      comp_id_4: string;
      comp_id_5: string;
      comp_id_6: string;
      comp_id_7: string;
      comp_id_8: string;
      comp_id_9: string;
      comp_id_10: string;
    }
  | undefined;

const GreenSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: styles.colorGreen,
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: styles.colorGreen,
  },
}));

export const DashboardContent = ({
  askRevId,
  startDate,
  paginationMaxDuration,
}: DashboardContentProps) => {
  const dispatch = useAppDispatch();
  const { tab } = useParams() as { tab: string };
  const activetab = useAppSelector(selectRecReviewTab);
  const pendingRecs = useAppSelector(selectPendingRecs);
  const allRowsSelected = useAppSelector(selectAllRowsSelected);
  const polling = useAppSelector(selectPolling);
  const uploading = useAppSelector(selectUploading);
  const { width } = useWindowDimensions();
  const [pinnedVal, setPinnedVal] = useState<
    boolean | 'left' | 'right' | null | undefined
  >('left');

  const history = useHistory();
  useEffect(() => {
    history.push(`/my-view/${activetab}`);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user, isReadOnly } = useUser();
  const userId = user?.id;

  const { showPast, toggleShowPast } = usePropertyContext();

  useEffect(() => {
    if (width) {
      const val = width <= 980 ? undefined : 'left';
      setPinnedVal(val);
    }
  }, [width]);

  const {
    handleRateCellChange,
    handleResetRates,
    handleSelectAll,
    handleSelectNone,
    handleTriggerUpload,
  } = useRateUpload();

  const property = useAppSelector(selectProperty);
  const { propertyId, currencyCode } = property;
  const { locale } = useUser();

  const showAskRev = useAppSelector(selectShowAskRev);
  const { exportData, exportHeader, setExportData, setExportHeader } =
    useExportContext();

  const { data, compSet, loading, missingStatusMap, refetchRateShop } =
    useDashboardController({
      propertyId,
      askRevId,
    });

  const actionsDisabled = loading || !data.length;

  const { data: intradayForecast } = useGetIntradayForecastQuery({
    skip: !propertyId,
    variables: { propertyId },
  });

  const { data: recRateExplainers, loading: loadingRecRateExplainers } =
    useGetRecRateQueryExplainersQuery({
      skip: !propertyId,
      variables: {
        propertyId,
        dateRange: {
          startDate: todaySubtract(1, 'week'),
          endDate: addDaysStr(paginationMaxDuration, today()),
        },
      },
    });

  const fixedColumns: ColumnObj[] = [
    {
      headerName: '',
      field: 'sellout_ind',
      headerComponentParams: { headerIcon: 'fa-flag' },
      suppressMovable: true,
      headerTooltip: labels.rec_review.dashboard.tooltip.alert,
      pinned: pinnedVal,
      lockPinned: true,
      lockPosition: true,
      width: 60,
      cellRenderer: (params) => {
        const t = RecAlert(params);
        return t;
      },
    },
    {
      headerName: '',
      headerComponentParams: { headerIcon: 'fa-circle' },
      field: 'priority',
      suppressMovable: true,
      pinned: pinnedVal,
      lockPinned: true,
      lockPosition: true,
      width: 60,
      headerTooltip: labels.rec_review.dashboard.tooltip.priority,
      cellRenderer: (params: { value: number }) => {
        const t = renderPriority(params.value);
        return t;
      },
    },
    {
      headerName: 'Date',
      field: 'stay_date',
      suppressMovable: true,
      lockPosition: true,
      pinned: pinnedVal,
      lockPinned: true,
      width: 70,
      headerComponentParams: { field: 'stay_date' },
      headerTooltip: labels.rec_review.dashboard.tooltip.date,
      cellRenderer: (params: ICellRendererParams) => {
        return <StayDateWithSeasonTooltip params={params} />;
      },
      sort: 'asc',
    },
    {
      headerName: 'DOW',
      field: 'dow',
      pinned: pinnedVal,
      lockPinned: true,
      width: 70,
      suppressMovable: true,
      lockPosition: true,
      headerTooltip: labels.rec_review.dashboard.tooltip.dow,
      comparator: (valueA: string, valueB: string) => {
        return daysOfWeekSort(valueA, valueB);
      },
    },
    {
      headerName: '',
      headerComponentParams: { headerIcon: <IoStar size={20} /> },
      field: 'is_favorite',
      pinned: pinnedVal,
      lockPinned: true,
      width: 60,
      headerTooltip: labels.rec_review.dashboard.tooltip.favorite,
      suppressMovable: true,
      lockPosition: true,
      cellRenderer: (params: ICellRendererParams & FavClickParams) => {
        return <FavStayDate params={params} />;
      },
      valueSetter: (params: ValueSetterParams) => {
        params.node?.setData({
          ...params.node?.data,
          is_favorite: params.newValue,
        });
        return params.newValue;
      },
    },
    // Hidden from display until fully implemented per B20V-2035:
    // {
    //   headerName: '',
    //   field: 'event',
    //   headerComponentParams: { headerIcon: 'fa-calendar' },
    //   suppressMovable: true,
    //   lockPosition: true,
    //   pinned: pinnedVal,
    //   lockPinned: true,
    //   width: 60,
    //   headerTooltip: labels.rec_review.dashboard.tooltip.event,
    //   tooltipField: 'event.eventDescription',
    // },
    {
      headerName: 'BWH\nRate',
      headerTooltip: labels.rec_review.dashboard.tooltip.bw_rate,
      field: 'current_rate_live',
      width: 100,
      pinned: pinnedVal,
      lockPinned: true,
      suppressMovable: true,
      lockPosition: true,
      cellRenderer: (params: ICellRendererParams) => {
        return renderLiveData(
          { ...params },
          'current_rate_live',
          'current_rate',
          true,
          twoDecimalRateFormatter
        );
      },
      comparator: (
        liveRateA: number,
        liveRateB: number,
        nodeA,
        nodeB,
        isDescending
      ) => {
        const rateA = liveRateA ?? nodeA.data?.current_rate;
        const rateB = liveRateB ?? nodeB.data?.current_rate;

        if (rateA === null) {
          return isDescending ? -1 : 1;
        }
        return rateA - rateB;
      },
    },
    {
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'inline-block' }}>{'Rec\nRate'}</span>
          <HelpOutline
            style={{
              display: 'inline-block',
              fontSize: '1rem',
              marginLeft: '2px',
            }}
          />
        </div>
      ),
      field: 'rec_rate',
      pinned: pinnedVal,
      lockPinned: true,
      width: 100,
      suppressMovable: true,
      lockPosition: true,
      headerTooltip: labels.rec_review.dashboard.tooltip.rec_rate,
      tooltipComponent: ({ value }: { value: string }) => {
        return (
          <div
            style={{
              border: '1px solid #babfc7',
              borderRadius: '3px',
              background: 'white',
              width: '11rem',
              padding: '6px 4px',
            }}
          >
            <div>{value}</div>
            <p
              style={{
                margin: '6px',
                fontSize: '0.75rem',
                color: styles.colorGrey80,
              }}
            >
              {labels.rec_review.dashboard.tooltip.rec_rate_desc}
            </p>
          </div>
        );
      },
      cellRenderer: (params: ICellRendererParams) => {
        const firstThreeWeeks = dayRange(today(), todayAdd(21, 'days'));
        const forecastData = intradayForecast?.getIntradayForecast?.find(
          (forecast) => dataDate(forecast?.stay_date) === params.data.stay_date
        );
        const explainerData = recRateExplainers?.getRecRateExplainers?.find(
          (explainer) => explainer?.stay_date === params.data?.stay_date
        );
        const recRate = data.find(
          (d) => d.stay_date === params.data.stay_date
        )?.rec_rate;

        if (firstThreeWeeks.includes(params.data.stay_date) && forecastData) {
          return (
            <ForecastInfoTooltip
              data={{
                newRecRate: recRate,
                prevRecRate: forecastData.prev_rec_rate,
                currentRate: forecastData.current_rate,
                changeInRecRate: forecastData.rate_variance,
                pricingRatio: explainerData?.pricing_ratio,
                pickupRatio: explainerData?.pickup_ratio,
                incrementalRevenue: explainerData?.incr_revenue,
                changeInMarketRate: forecastData.delta_market_rate,
                reasons: forecastData.reasons,
                lastHourPickup: forecastData.last_hour_pkp,
                expectedPickup: forecastData.expected_pkp,
                actualPickup: forecastData.actual_pkp,
                expectedAllDayPickup: forecastData.expected_allday_pkp,
                expectedAllDayPickupUnconstrained:
                  forecastData.expected_allday_pkp_unc,
                snapshotHour: forecastData.snapshot_hour,
                lastUpdatedDate: forecastData.last_updated_date,
              }}
            >
              <div>
                {formattedCurrency(
                  params.value,
                  locale,
                  currencyCode,
                  true,
                  twoDecimalRateFormatter
                )}
              </div>
            </ForecastInfoTooltip>
          );
        }
        return (
          <PriceDecisionTooltip
            data={recRateExplainers?.getRecRateExplainers?.find(
              (explainer) => explainer?.stay_date === params.data?.stay_date
            )}
            loading={loadingRecRateExplainers}
          >
            <div>
              {formattedCurrency(
                params.value,
                locale,
                currencyCode,
                true,
                twoDecimalRateFormatter
              )}
            </div>
          </PriceDecisionTooltip>
        );
      },
    },
    {
      headerName: 'Rate to\nUpload',
      field: 'override_rate',
      width: 100,
      editable: (params: EditableCallbackParams) => {
        const isPast = params.data.stay_date < today();
        return !isPast && !isReadOnly;
      },
      pinned: pinnedVal,
      lockPinned: true,
      suppressMovable: true,
      lockPosition: true,
      headerTooltip: labels.rec_review.dashboard.tooltip.rate_to_upload,
      singleClickEdit: true,
      cellRenderer: (params: ICellRendererParams) => {
        const isPast = params.data.stay_date < today();
        return isPast ? null : <OverrideCell {...params} />;
      },
      cellEditorSelector: (params: ICellEditorParams) => {
        const isPast = params.data.stay_date < today();
        return isPast
          ? undefined
          : {
              component: 'numericCellEditor',
              params: {
                currencyCode,
                locale,
                twoDecimalRateFormatter,
              },
            };
      },
      onCellValueChanged: (params: NewValueParams) => {
        handleRateCellChange(params);
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return formattedCurrency(
          params.value,
          locale,
          currencyCode,
          true,
          twoDecimalRateFormatter
        );
      },
      valueSetter: (params: ValueSetterParams) => {
        params.node?.setData({
          ...params.node?.data,
          override_rate: params.newValue,
        });
        return params.newValue;
      },
    },
    {
      headerName: 'Rec\nStatus',
      field: 'rec_status',
      pinned: pinnedVal,
      lockPinned: true,
      headerTooltip: labels.rec_review.dashboard.tooltip.rec_status,
      suppressMovable: true,
      lockPosition: true,
      width: 100,
      cellRenderer: (params: any) => {
        return renderRecStatusCellStyle(params);
      },
    },
    {
      headerName: 'Market\nRate',
      headerTooltip: labels.rec_review.dashboard.tooltip.market_rate,
      field: 'market_rate',
      suppressMovable: true,
      lockPosition: true,
      pinned: pinnedVal,
      lockPinned: true,
      width: 100,
      /*  valueFormatter: (params: ValueFormatterParams) => {
        return twoDecimalRateFormatter(params.value);
      }, */
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <RateCellRender
            params={params}
            digitFormatter={twoDecimalRateFormatter}
          />
        );
      },
      headerClass: ['age-border'],
      cellClass: ['age-border'],
    },
  ];

  const {
    competitorCols,
    forecastCols,
    inventoryCols,
    kpiCols,
    metricsCols,
    pickupCols,
    ratesCols,
    handleColumnMoved,
    addColumnToMetric,
  } = useDashboardColumns(fixedColumns.length, compSet);

  const { isShown: isResetModalShown, toggle: toggleResetModal } = useModal();

  const { updateUserHotelMetrics } = useMyMetrics({
    propertyId: propertyId!,
    userId: userId!,
  });

  const handleResetColumns = () => {
    const defaultTabs = {
      metrics: [
        'available_rooms_live',
        'transient_capacity_live',
        'total_fcst_incl_group',
        'current_otb_live',
        'adr_live',
        'revpar_live',
        'occ_pct_live',
        'fcst_occ_pct',
      ],
    };

    updateUserHotelMetrics({
      variables: {
        userId: userId!,
        propertyId: propertyId!,
        metricCols: JSON.stringify({
          tabs: defaultTabs,
        }),
      },
    });
    dispatch(setMyMetrics(undefined));
    dispatch(setTabs(defaultTabs));
    toggleResetModal();
  };

  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    if (gridRef.current && propertyId) {
      gridRef.current.api?.forEachNode((row) => {
        if (row.data?.rec_status === 'Pending') {
          row.setSelected(true);
        } else {
          row.setSelected(false);
        }
      });
    }
  }, [gridRef, propertyId]);

  const MoreOptionEleIcon: React.FC = () => (
    <MdMoreHoriz size={22} className='dashboard__options-icon' />
  );

  const onSelectAllRows = () => {
    const selectAll = !allRowsSelected;

    if (selectAll) {
      handleSelectAll(gridRef);
      dispatch(setAllRowsSelected(selectAll));
    } else {
      handleSelectNone(gridRef);
      dispatch(setAllRowsSelected(selectAll));
    }
  };

  const onRateResetClick = () => {
    handleResetRates(gridRef);
  };

  const getColumns = () => {
    if (tab === 'forecast') {
      const column = [...fixedColumns, ...forecastCols];
      return column;
    } else if (tab === 'metrics') {
      const column = [...fixedColumns, ...metricsCols];
      return column;
    } else if (tab === 'inventory') {
      const column = [...fixedColumns, ...inventoryCols];
      return column;
    } else if (tab === 'pickup') {
      const column = [...fixedColumns, ...pickupCols];
      return column;
    } else if (tab === 'rates') {
      const column = [...fixedColumns, ...ratesCols];
      return column;
    } else if (tab === 'kpi') {
      const column = [...fixedColumns, ...kpiCols];
      return column;
    } else {
      if (compSet) {
        const allCompetitorsCols = competitorCols(compSet) || [];
        const filterCompetitorsCols = allCompetitorsCols.filter(
          (c) => !!c.headerName && !!c.headerTooltip
        );
        const column = [...fixedColumns, ...filterCompetitorsCols];
        return column;
      } else {
        return fixedColumns;
      }
    }
  };

  const tableColumn = getColumns();

  const getFileName = useMemo(() => {
    let d = new Date();
    let dformat =
      d.getDate() +
      '_' +
      d.toString().substr(4, 3) +
      '_' +
      d.getFullYear() +
      '_' +
      d.getHours() +
      d.getMinutes() +
      d.getSeconds();
    return `${propertyId}_${tab}_` + dformat + '.csv';
  }, [propertyId, tab]);

  const replaceNullOrZeroWithDash = (value: number | null | undefined) => {
    return value !== null && value !== undefined && value !== 0 ? value : '-';
  };

  const processDataForCSV = useCallback(() => {
    return exportData.map((item: any) => {
      const totalForecast = calcTotalForecast(item);
      // Get all live data and format it to be exported
      const liveData = pickBy(item, (value, key) => key.includes('_live'));
      // Remove _live from the key and keep live data if present
      const newObject: { [key: string]: number | null } = {};
      Object.keys(liveData).forEach((key) => {
        const formattedKey = key.replace('_live', '');
        return (newObject[formattedKey] = liveData[key]);
      });
      return {
        ...item,
        ...newObject,
        a_fcst_sell: `${replaceNullOrZeroWithDash(
          item.a_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.a_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.a_status)}`,
        b_fcst_sell: `${replaceNullOrZeroWithDash(
          item.b_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.b_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.b_status)}`,
        c_fcst_sell: `${replaceNullOrZeroWithDash(
          item.c_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.c_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.c_status)}`,
        d_fcst_sell: `${replaceNullOrZeroWithDash(
          item.d_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.d_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.d_status)}`,
        e_fcst_sell: `${replaceNullOrZeroWithDash(
          item.e_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.e_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.e_status)}`,
        f_fcst_sell: `${replaceNullOrZeroWithDash(
          item.f_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.f_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.f_status)}`,
        g_fcst_sell: `${replaceNullOrZeroWithDash(
          item.g_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.g_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.g_status)}`,
        h_fcst_sell: `${replaceNullOrZeroWithDash(
          item.h_current_otb
        )}/${replaceNullOrZeroWithDash(
          item.h_fcst_sell
        )}/${replaceNullOrZeroWithDash(item.h_status)}`,
        total_fcst_incl_group: totalForecast?.total_fcst_incl_group,
        total_fcst_incl_group_live: totalForecast?.total_fcst_incl_group_live,
        fcst_occ_pct:
          totalForecast?.fcst_occ_pct_live !== undefined
            ? totalForecast.fcst_occ_pct_live.toFixed(1)
            : totalForecast?.fcst_occ_pct?.toFixed(1),
      };
    });
  }, [exportData]);

  useEffect(() => {
    const headers = getCsvFormatHeader(tableColumn);

    const exportHeaders = headers.map((header) => {
      if (header.key.includes('live')) {
        const keyMapping = {
          transient_capacity: 'transient_cap',
          ooo: 'out_of_order',
        };

        const mappedKey =
          keyMapping[
            header.key.replace('_live', '') as keyof typeof keyMapping
          ] || header.key.replace('_live', '');
        return {
          label: header.label,
          key: mappedKey,
        };
      }
      if (header.key.includes('compRates')) {
        return {
          label: header.label,
          key: `${header.key}.value`,
        };
      }
      return header;
    });

    setExportHeader?.(exportHeaders);
    setExportData?.(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tab]);

  const [missingWarningCalled, setMissingWarningCalled] = useState(false);
  useEffect(() => {
    if (missingStatusMap.size > 1 && !loading && !missingWarningCalled) {
      setMissingWarningCalled(true);
      dispatch(
        alertAdded(
          'warning',
          labels.rec_review.dashboard.missing_status_alert,
          '/help/94b9fc1c-dc32-43bb-9529-e03bf4b7630b'
        )
      );
    }
    return () => {
      setMissingWarningCalled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading]);

  const agGridData = useMemo(() => {
    const defaultAskrevColumnDef = [
      { field: 'default_column', maxWidth: '100%', headerName: '' },
    ];

    const noData = [{ default_column: labels.ask_best_rev.select_option_msg }];

    const noAskRevId = showAskRev && askRevId === '';

    return {
      column: noAskRevId ? defaultAskrevColumnDef : tableColumn,
      data: noAskRevId ? noData : data,
    };
  }, [data, showAskRev, askRevId, tableColumn]);

  const { isShown: isRateShopModalShown, toggle: toggleRateShopModal } =
    useModal();

  const moreOptions: Array<MenuOptionType> = [
    /* TODO B20V-2284 Hiding moreOptions until they are ready */
    //...moreOptionItems,
    {
      id: 6,
      title: labels.rec_review.dashboard.more_action.reset_columns.header,
      disabled: actionsDisabled,
      onClick: toggleResetModal,
    },
    {
      id: 7,
      title: 'Rate Shop Options',
      onClick: toggleRateShopModal,
    },
  ];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { over, active } = event;
    const { action, source } = active.data.current ?? {};

    if (over && action === 'add') {
      addColumnToMetric(active.id as string, source);
    }
  }

  return (
    <>
      <Modal
        isShown={isResetModalShown}
        hide={toggleResetModal}
        headerText={
          labels.rec_review.dashboard.more_action.reset_columns.header
        }
        messageBody={
          labels.rec_review.dashboard.more_action.reset_columns.message
        }
        isConfirmationModal={true}
        confirmBtnText={
          labels.rec_review.dashboard.more_action.reset_columns.confirm
        }
        cancelBtnText={
          labels.rec_review.dashboard.more_action.reset_columns.cancel
        }
        onConfirm={handleResetColumns}
        onCancel={toggleResetModal}
      />
      <RateShopModal
        isShown={isRateShopModalShown}
        toggle={toggleRateShopModal}
        refetchRateShop={refetchRateShop}
      />
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <div className='dashboard__header'>
          <div className='tw-flex tw-flex-row tw-gap-2'>
            <PendingRecsStatusMessage />
            <RateChangeJobStatus />
          </div>

          <div className='dashboard__options_wrapper'>
            <div className='dashboard__options'>
              <Tooltip
                title={labels.rec_review.dashboard.more_action.select_all}
                arrow
                placement='top'
              >
                <span>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    aria-label='all-rows'
                    className='dashboard__options-btn'
                    onClick={onSelectAllRows}
                    disabled={actionsDisabled || isReadOnly}
                  >
                    <MdCheckCircleOutline size={22} />
                  </IconButton>
                </span>
              </Tooltip>

              <RateUploadButton
                disabled={
                  pendingRecs && pendingRecs.length > 0 && !isReadOnly
                    ? false
                    : true
                }
                handleTriggerUpload={() => handleTriggerUpload(gridRef)}
                polling={polling}
                uploading={uploading}
                className='dashboard__options-btn'
              />
              <Tooltip
                title={labels.rec_review.dashboard.more_action.refresh}
                arrow
                placement='top'
              >
                <span>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    aria-label='reset'
                    className='dashboard__options-btn'
                    onClick={onRateResetClick}
                    disabled={
                      pendingRecs && pendingRecs.length > 0 && !isReadOnly
                        ? false
                        : true
                    }
                  >
                    <MdRefresh size={22} />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip
                title={labels.rec_review.dashboard.more_action.graph}
                arrow
                placement='top'
              >
                <IconButton
                  disableFocusRipple
                  disableRipple
                  aria-label='graph'
                  className='dashboard__options-btn'
                >
                  <Link to='/my-view/graph'>
                    <BsFillFileBarGraphFill size={22} />
                  </Link>
                </IconButton>
              </Tooltip>

              <Tooltip
                title={labels.rec_review.dashboard.more_action.export}
                arrow
                placement='top'
              >
                <div>
                  <ExportCSV
                    csvHeaders={exportHeader}
                    csvData={processDataForCSV()}
                    fileName={getFileName}
                    content={
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        aria-label='export'
                        className='dashboard__options-btn export-btn'
                        disabled={actionsDisabled}
                      >
                        <MdSimCardDownload size={22} />
                      </IconButton>
                    }
                  />
                </div>
              </Tooltip>
              <Tooltip
                title={
                  showPast
                    ? labels.rec_review.dashboard.more_action.hide_past
                    : labels.rec_review.dashboard.more_action.show_past
                }
                arrow
                placement='top'
              >
                <GreenSwitch
                  checked={showPast}
                  onChange={toggleShowPast}
                  size='small'
                />
              </Tooltip>

              <Tooltip
                title={labels.rec_review.dashboard.more_action.more_options}
                arrow
                placement='top'
              >
                <div className='dashboard__options-more'>
                  <DropdownList
                    content={<MoreOptionEleIcon />}
                    menu={moreOptions}
                  />
                </div>
              </Tooltip>
            </div>
            <Tabs />
          </div>
        </div>
        <div className='dashboard__tables'>
          <LoadingBar
            loading={loading}
            overlayStyle={{
              borderRadius: '12px',
              backgroundColor: ' rgba(0, 0, 0, 0.15)',
            }}
          >
            <DashboardTable
              columns={agGridData.column}
              data={agGridData.data}
              gridRef={gridRef}
              paginationMaxDuration={paginationMaxDuration}
              handleColumnMoved={handleColumnMoved}
              fixedColsLength={fixedColumns.length}
            />
          </LoadingBar>
        </div>
      </DndContext>
    </>
  );
};
